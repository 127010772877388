const hsk1Dict = {
  0: { character: "爱", pinyin: "Ài", meaning: "Love" },
  1: { character: "八", pinyin: "bā", meaning: "Eight" },
  2: { character: "爸爸", pinyin: "bàba", meaning: "dad" },
  3: { character: "杯子", pinyin: "bēizi", meaning: "cup" },
  4: { character: "北京", pinyin: "běijīng", meaning: "Beijing" },
  5: { character: "本", pinyin: "běn", meaning: "Book" },
  6: { character: "不", pinyin: "bù", meaning: "Do not" },
  7: { character: "不客气", pinyin: "bù kèqì", meaning: "You're welcome" },
  8: { character: "菜", pinyin: "cài", meaning: "vegetable" },
  9: { character: "茶", pinyin: "chá", meaning: "Tea" },
  10: { character: "吃", pinyin: "chī", meaning: "eat" },
  11: { character: "出租车", pinyin: "chūzū chē", meaning: "taxi" },
  12: { character: "打电话", pinyin: "dǎ diànhuà", meaning: "Call up" },
  13: { character: "大", pinyin: "dà", meaning: "Big" },
  14: { character: "的", pinyin: "de", meaning: "of" },
  15: { character: "点", pinyin: "diǎn", meaning: "point" },
  16: { character: "电脑", pinyin: "diànnǎo", meaning: "computer" },
  17: { character: "电视", pinyin: "diànshì", meaning: "television" },
  18: { character: "电影", pinyin: "diànyǐng", meaning: "Movie" },
  19: { character: "东西", pinyin: "dōngxī", meaning: "thing" },
  20: { character: "都", pinyin: "dōu", meaning: "All" },
  21: { character: "读", pinyin: "dú", meaning: "read" },
  22: { character: "对不起", pinyin: "duìbùqǐ", meaning: "sorry" },
  23: { character: "多", pinyin: "duō", meaning: "many" },
  24: { character: "多少", pinyin: "duōshǎo", meaning: "How many" },
  25: { character: "儿子", pinyin: "érzi", meaning: "son" },
  26: { character: "二", pinyin: "èr", meaning: "two" },
  27: { character: "饭店", pinyin: "fàndiàn", meaning: "restaurant" },
  28: { character: "飞机", pinyin: "fēijī", meaning: "airplane" },
  29: { character: "分钟", pinyin: "fēnzhōng", meaning: "minute" },
  30: { character: "高兴", pinyin: "gāoxìng", meaning: "Happy" },
  31: { character: "个", pinyin: "gè", meaning: "individual" },
  32: { character: "工作", pinyin: "gōngzuò", meaning: "Work" },
  33: { character: "狗", pinyin: "gǒu", meaning: "dog" },
  34: { character: "汉语", pinyin: "hànyǔ", meaning: "Chinese" },
  35: { character: "好", pinyin: "hǎo", meaning: "it is good" },
  36: { character: "号", pinyin: "hào", meaning: "day of a month, number" },
  37: { character: "喝", pinyin: "hē", meaning: "drink" },
  38: { character: "和", pinyin: "hé", meaning: "and" },
  39: { character: "很", pinyin: "hěn", meaning: "very" },
  40: { character: "后面", pinyin: "hòumiàn", meaning: "Behind" },
  41: { character: "回", pinyin: "huí", meaning: "back, return" },
  42: { character: "会", pinyin: "huì", meaning: "meeting, can, be able to" },
  43: { character: "几", pinyin: "jǐ", meaning: "Several" },
  44: { character: "家", pinyin: "jiā", meaning: "Family" },
  45: { character: "叫", pinyin: "jiào", meaning: "Call" },
  46: { character: "今天", pinyin: "jīntiān", meaning: "today" },
  47: { character: "九", pinyin: "jiǔ", meaning: "Nine" },
  48: { character: "开", pinyin: "kāi", meaning: "open" },
  49: { character: "看", pinyin: "kàn", meaning: "Look" },
  50: { character: "看见", pinyin: "kànjiàn", meaning: "see" },
  51: { character: "块", pinyin: "kuài", meaning: "piece" },
  52: { character: "来", pinyin: "lái", meaning: "Come" },
  53: { character: "老师", pinyin: "lǎo shī", meaning: "teacher" },
  54: { character: "了", pinyin: "le", meaning: "span" },
  55: { character: "冷", pinyin: "lěng", meaning: "cold" },
  56: { character: "里", pinyin: "lǐ", meaning: "inside" },
  57: { character: "六", pinyin: "liù", meaning: "six" },
  58: { character: "妈妈", pinyin: "māmā", meaning: "Mother" },
  59: { character: "吗", pinyin: "ma", meaning: "Is it?(question particle)" },
  60: { character: "买", pinyin: "mǎi", meaning: "purchase, buy" },
  61: { character: "猫", pinyin: "māo", meaning: "cat" },
  62: { character: "没关系", pinyin: "méiguānxì", meaning: "It's ok" },
  63: { character: "没有", pinyin: "méiyǒu", meaning: "No" },
  64: { character: "米饭", pinyin: "mǐfàn", meaning: "rice" },
  65: { character: "名字", pinyin: "míngzì", meaning: "name" },
  66: { character: "明天", pinyin: "míngtiān", meaning: "tomorrow" },
  67: { character: "哪", pinyin: "nǎ", meaning: "where" },
  68: { character: "哪儿", pinyin: "nǎ'er", meaning: "where" },
  69: { character: "那", pinyin: "nà", meaning: "That" },
  70: { character: "呢", pinyin: "ne", meaning: "Woolen cloth" },
  71: { character: "能", pinyin: "néng", meaning: "can" },
  72: { character: "你", pinyin: "nǐ", meaning: "you" },
  73: { character: "年", pinyin: "nián", meaning: "year" },
  74: { character: "女儿", pinyin: "nǚ'ér", meaning: "daughter" },
  75: { character: "朋友", pinyin: "péngyǒu", meaning: "friend" },
  76: { character: "漂亮", pinyin: "piàoliang", meaning: "pretty" },
  77: { character: "苹果", pinyin: "píngguǒ", meaning: "apple" },
  78: { character: "七", pinyin: "qī", meaning: "seven" },
  79: { character: "前面", pinyin: "qiánmiàn", meaning: "Front" },
  80: { character: "钱", pinyin: "qián", meaning: "money" },
  81: { character: "请", pinyin: "qǐng", meaning: "Please" },
  82: { character: "去", pinyin: "qù", meaning: "go" },
  83: { character: "热", pinyin: "rè", meaning: "hot" },
  84: { character: "人", pinyin: "rén", meaning: "people" },
  85: { character: "认识", pinyin: "rènshí", meaning: "know" },
  86: { character: "三", pinyin: "sān", meaning: "three" },
  87: { character: "商店", pinyin: "shāngdiàn", meaning: "shop" },
  88: { character: "上", pinyin: "shàng", meaning: "superior" },
  89: { character: "上午", pinyin: "shàngwǔ", meaning: "morning" },
  90: { character: "少", pinyin: "shǎo", meaning: "few" },
  91: { character: "谁", pinyin: "shéi", meaning: "Who" },
  92: { character: "什么", pinyin: "shénme", meaning: "what" },
  93: { character: "十", pinyin: "shí", meaning: "ten" },
  94: { character: "时候", pinyin: "shíhòu", meaning: "when" },
  95: { character: "是", pinyin: "shì", meaning: "Yes" },
  96: { character: "书", pinyin: "shū", meaning: "Book" },
  97: { character: "水", pinyin: "shuǐ", meaning: "water" },
  98: { character: "水果", pinyin: "shuǐguǒ", meaning: "fruit" },
  99: { character: "睡觉", pinyin: "shuìjiào", meaning: "sleep" },
  100: { character: "说", pinyin: "shuō", meaning: "Say" },
  101: { character: "四", pinyin: "sì", meaning: "Four" },
  102: { character: "岁", pinyin: "suì", meaning: "age" },
  103: { character: "他", pinyin: "tā", meaning: "he" },
  104: { character: "她", pinyin: "tā", meaning: "she" },
  105: { character: "太", pinyin: "tài", meaning: "too" },
  106: { character: "天气", pinyin: "tiānqì", meaning: "weather" },
  107: { character: "听", pinyin: "tīng", meaning: "listen" },
  108: { character: "同学", pinyin: "tóngxué", meaning: "classmate" },
  109: {
    character: "喂（叹词）",
    pinyin: "wèi (tàn cí)",
    meaning: "hello (interjection)",
  },
  110: { character: "我", pinyin: "wǒ", meaning: "I" },
  111: { character: "我们", pinyin: "wǒmen", meaning: "us" },
  112: { character: "五", pinyin: "wǔ", meaning: "five" },
  113: { character: "喜欢", pinyin: "xǐhuān", meaning: "like" },
  114: { character: "下", pinyin: "xià", meaning: "Down" },
  115: { character: "下午", pinyin: "xiàwǔ", meaning: "afternoon" },
  116: { character: "下雨", pinyin: "xià yǔ", meaning: "rain" },
  117: { character: "先生", pinyin: "xiānshēng", meaning: "gentlemen" },
  118: { character: "现在", pinyin: "xiànzài", meaning: "Now" },
  119: { character: "想", pinyin: "xiǎng", meaning: "think" },
  120: { character: "小", pinyin: "xiǎo", meaning: "Small" },
  121: { character: "小姐", pinyin: "xiǎojiě", meaning: "Miss" },
  122: { character: "些", pinyin: "xiē", meaning: "some" },
  123: { character: "写", pinyin: "xiě", meaning: "Write" },
  124: { character: "谢谢", pinyin: "xièxiè", meaning: "thanks" },
  125: { character: "星期", pinyin: "xīngqí", meaning: "Week" },
  126: { character: "学生", pinyin: "xuéshēng", meaning: "student" },
  127: { character: "学习", pinyin: "xuéxí", meaning: "study" },
  128: { character: "学校", pinyin: "xuéxiào", meaning: "School" },
  129: { character: "一", pinyin: "yī", meaning: "one" },
  130: { character: "一点儿", pinyin: "yī diǎn er", meaning: "a little" },
  131: { character: "衣服", pinyin: "yīfú", meaning: "clothing" },
  132: { character: "医生", pinyin: "yīshēng", meaning: "doctor" },
  133: { character: "医院", pinyin: "yīyuàn", meaning: "Hospital" },
  134: { character: "椅子", pinyin: "yǐzi", meaning: "Chair" },
  135: { character: "有", pinyin: "yǒu", meaning: "Have" },
  136: { character: "月", pinyin: "yuè", meaning: "moon" },
  137: { character: "再见", pinyin: "zàijiàn", meaning: "goodbye" },
  138: { character: "在", pinyin: "zài", meaning: "exist" },
  139: { character: "怎么", pinyin: "zěnme", meaning: "how" },
  140: { character: "怎么样", pinyin: "zěnme yàng", meaning: "How about it" },
  141: { character: "这", pinyin: "zhè", meaning: "This" },
  142: { character: "中国", pinyin: "zhōngguó", meaning: "China" },
  143: { character: "中午", pinyin: "zhōngwǔ", meaning: "noon" },
  144: { character: "住", pinyin: "zhù", meaning: "live" },
  145: { character: "桌子", pinyin: "zhuōzi", meaning: "table" },
  146: { character: "字", pinyin: "zì", meaning: "character" },
  147: { character: "昨天", pinyin: "zuótiān", meaning: "yesterday" },
  148: { character: "坐", pinyin: "zuò", meaning: "sit" },
  149: { character: "做", pinyin: "zuò", meaning: "Do" },
};

const hsk2Dict = {
  0: { character: "吧", pinyin: "ba", meaning: "Bar" },
  1: { character: "白", pinyin: "bái", meaning: "White" },
  2: { character: "百", pinyin: "bǎi", meaning: "Hundred" },
  3: { character: "帮助", pinyin: "bāngzhù", meaning: "help" },
  4: { character: "报纸", pinyin: "bàozhǐ", meaning: "newspaper" },
  5: { character: "比", pinyin: "bǐ", meaning: "Compare" },
  6: { character: "别", pinyin: "bié", meaning: "do not" },
  7: { character: "宾馆", pinyin: "bīnguǎn", meaning: "hotel" },
  8: {
    character: "长（形容词）",
    pinyin: "zhǎng (xíngróngcí)",
    meaning: "long (adjective)",
  },
  9: { character: "唱歌", pinyin: "chànggē", meaning: "Sing" },
  10: { character: "出", pinyin: "chū", meaning: "out" },
  11: { character: "穿", pinyin: "chuān", meaning: "Put on" },
  12: { character: "次", pinyin: "cì", meaning: "Second-rate" },
  13: { character: "从", pinyin: "cóng", meaning: "from" },
  14: { character: "错", pinyin: "cuò", meaning: "wrong" },
  15: { character: "打篮球", pinyin: "dǎ lánqiú", meaning: "play basketball" },
  16: { character: "大家", pinyin: "dàjiā", meaning: "Everyone" },
  17: { character: "到", pinyin: "dào", meaning: "arrive" },
  18: {
    character: "得（助词）",
    pinyin: "dé (zhùcí)",
    meaning: "get (particle)",
  },
  19: {
    character: "等（动词）",
    pinyin: "děng (dòngcí)",
    meaning: "wait (verb)",
  },
  20: { character: "弟弟", pinyin: "dìdì", meaning: "younger brother" },
  21: { character: "第一", pinyin: "dì yī", meaning: "First" },
  22: { character: "懂", pinyin: "dǒng", meaning: "Understand" },
  23: {
    character: "对（形容词）",
    pinyin: "duì (xíngróngcí)",
    meaning: "right (adjective)",
  },
  24: {
    character: "对（介词）",
    pinyin: "duì (jiècí)",
    meaning: "right (preposition)",
  },
  25: { character: "房间", pinyin: "fángjiān", meaning: "Room" },
  26: { character: "非常", pinyin: "fēicháng", meaning: "Very" },
  27: { character: "服务员", pinyin: "fúwùyuán", meaning: "waiter" },
  28: { character: "高", pinyin: "gāo", meaning: "high" },
  29: { character: "告诉", pinyin: "gàosù", meaning: "Tell" },
  30: { character: "哥哥", pinyin: "gēgē", meaning: "elder brother" },
  31: { character: "给", pinyin: "gěi", meaning: "Give" },
  32: { character: "公共汽车", pinyin: "gōnggòng qìchē", meaning: "bus" },
  33: { character: "公司", pinyin: "gōngsī", meaning: "company" },
  34: { character: "贵", pinyin: "guì", meaning: "expensive" },
  35: {
    character: "过（助词）",
    pinyin: "guò (zhùcí)",
    meaning: "passed (particle)",
  },
  36: {
    character: "还（副词）",
    pinyin: "hái (fùcí)",
    meaning: "also (adverb)",
  },
  37: { character: "孩子", pinyin: "háizi", meaning: "child" },
  38: { character: "好吃", pinyin: "hào chī", meaning: "good to eat" },
  39: { character: "黑", pinyin: "hēi", meaning: "black" },
  40: { character: "红", pinyin: "hóng", meaning: "red" },
  41: { character: "火车站", pinyin: "huǒchē zhàn", meaning: "TRAIN STATION" },
  42: { character: "机场", pinyin: "jīchǎng", meaning: "Airport" },
  43: { character: "鸡蛋", pinyin: "jīdàn", meaning: "egg" },
  44: { character: "件", pinyin: "jiàn", meaning: "piece" },
  45: { character: "教室", pinyin: "jiàoshì", meaning: "classroom" },
  46: { character: "姐姐", pinyin: "jiějiě", meaning: "elder sister" },
  47: { character: "介绍", pinyin: "jièshào", meaning: "introduce" },
  48: { character: "进", pinyin: "jìn", meaning: "Enter" },
  49: { character: "近", pinyin: "jìn", meaning: "close" },
  50: { character: "就", pinyin: "jiù", meaning: "At once" },
  51: { character: "觉得", pinyin: "juédé", meaning: "feel" },
  52: { character: "咖啡", pinyin: "kāfēi", meaning: "coffee" },
  53: { character: "开始", pinyin: "kāishǐ", meaning: "start" },
  54: { character: "考试", pinyin: "kǎoshì", meaning: "take an exam" },
  55: { character: "可能", pinyin: "kěnéng", meaning: "possible" },
  56: { character: "可以", pinyin: "kěyǐ", meaning: "Can" },
  57: { character: "课", pinyin: "kè", meaning: "class" },
  58: { character: "快", pinyin: "kuài", meaning: "quick" },
  59: { character: "快乐", pinyin: "kuàilè", meaning: "hapiness" },
  60: { character: "累", pinyin: "lèi", meaning: "tired" },
  61: { character: "离", pinyin: "lí", meaning: "Leave" },
  62: { character: "两", pinyin: "liǎng", meaning: "two" },
  63: { character: "零", pinyin: "líng", meaning: "zero" },
  64: { character: "路", pinyin: "lù", meaning: "road" },
  65: { character: "旅游", pinyin: "lǚyóu", meaning: "travel" },
  66: { character: "卖", pinyin: "mài", meaning: "Sell" },
  67: { character: "慢", pinyin: "màn", meaning: "slow" },
  68: { character: "忙", pinyin: "máng", meaning: "busy" },
  69: { character: "每", pinyin: "měi", meaning: "Every" },
  70: { character: "妹妹", pinyin: "mèimei", meaning: "younger sister" },
  71: { character: "门", pinyin: "mén", meaning: "Door" },
  72: { character: "面条", pinyin: "miàntiáo", meaning: "Noodle" },
  73: { character: "男", pinyin: "nán", meaning: "male" },
  74: { character: "您", pinyin: "nín", meaning: "you" },
  75: { character: "牛奶", pinyin: "niúnǎi", meaning: "milk" },
  76: { character: "女", pinyin: "Nǚ", meaning: "Female" },
  77: { character: "旁边", pinyin: "pángbiān", meaning: "beside" },
  78: { character: "跑步", pinyin: "pǎobù", meaning: "Run" },
  79: { character: "便宜", pinyin: "piányí", meaning: "Cheap" },
  80: { character: "票", pinyin: "piào", meaning: "ticket" },
  81: { character: "妻子", pinyin: "qīzi", meaning: "wife" },
  82: { character: "起床", pinyin: "qǐchuáng", meaning: "get up" },
  83: { character: "千", pinyin: "qiān", meaning: "thousand" },
  84: { character: "铅笔", pinyin: "qiānbǐ", meaning: "pencil" },
  85: { character: "晴", pinyin: "qíng", meaning: "sunny" },
  86: { character: "去年", pinyin: "qùnián", meaning: "last year" },
  87: { character: "让", pinyin: "ràng", meaning: "Let" },
  88: { character: "日", pinyin: "rì", meaning: "day" },
  89: { character: "上班", pinyin: "shàngbān", meaning: "work" },
  90: { character: "身体", pinyin: "shēntǐ", meaning: "Body" },
  91: { character: "生病", pinyin: "shēngbìng", meaning: "Get ill" },
  92: { character: "生日", pinyin: "shēngrì", meaning: "Birthday" },
  93: { character: "时间", pinyin: "shíjiān", meaning: "time" },
  94: { character: "事情", pinyin: "shìqíng", meaning: "matter" },
  95: { character: "手表", pinyin: "shǒubiǎo", meaning: "watch" },
  96: { character: "手机", pinyin: "shǒujī", meaning: "cell phone" },
  97: { character: "说话", pinyin: "shuōhuà", meaning: "say" },
  98: { character: "送", pinyin: "sòng", meaning: "deliver" },
  99: {
    character: "虽然……但是……",
    pinyin: "suīrán……dànshì……",
    meaning: "However, although……",
  },
  100: { character: "它", pinyin: "tā", meaning: "it" },
  101: { character: "踢足球", pinyin: "tī zúqiú", meaning: "play football" },
  102: { character: "题", pinyin: "tí", meaning: "question" },
  103: { character: "跳舞", pinyin: "tiàowǔ", meaning: "Dance" },
  104: { character: "外", pinyin: "wài", meaning: "outside" },
  105: { character: "完", pinyin: "wán", meaning: "Finish" },
  106: { character: "玩", pinyin: "wán", meaning: "Play" },
  107: { character: "晚上", pinyin: "wǎnshàng", meaning: "night" },
  108: { character: "往", pinyin: "wǎng", meaning: "Past" },
  109: { character: "为什么", pinyin: "wèishéme", meaning: "why" },
  110: { character: "问", pinyin: "wèn", meaning: "ask" },
  111: { character: "问题", pinyin: "wèntí", meaning: "question" },
  112: { character: "西瓜", pinyin: "xīguā", meaning: "watermelon" },
  113: { character: "希望", pinyin: "xīwàng", meaning: "hope" },
  114: { character: "洗", pinyin: "xǐ", meaning: "wash" },
  115: { character: "小时", pinyin: "xiǎoshí", meaning: "Hour" },
  116: { character: "笑", pinyin: "xiào", meaning: "laugh" },
  117: { character: "新", pinyin: "xīn", meaning: "new" },
  118: { character: "姓", pinyin: "xìng", meaning: "surname" },
  119: { character: "休息", pinyin: "xiūxí", meaning: "rest" },
  120: { character: "雪", pinyin: "xuě", meaning: "Snow" },
  121: { character: "颜色", pinyin: "yánsè", meaning: "color" },
  122: { character: "眼睛", pinyin: "yǎnjīng", meaning: "Eye" },
  123: { character: "羊肉", pinyin: "yángròu", meaning: "mutton" },
  124: { character: "药", pinyin: "yào", meaning: "medicine" },
  125: { character: "要", pinyin: "yào", meaning: "want" },
  126: { character: "也", pinyin: "yě", meaning: "also" },
  127: { character: "一起", pinyin: "yīqǐ", meaning: "Together" },
  128: { character: "一下", pinyin: "yīxià", meaning: "a bit" },
  129: { character: "已经", pinyin: "yǐjīng", meaning: "already" },
  130: { character: "意思", pinyin: "yìsi", meaning: "to mean, meaning" },
  131: {
    character: "因为……所以……",
    pinyin: "yīnwèi……suǒyǐ……",
    meaning: "Because Therefore……",
  },
  132: { character: "阴", pinyin: "yīn", meaning: "Negative" },
  133: { character: "游泳", pinyin: "yóuyǒng", meaning: "swim" },
  134: { character: "右边", pinyin: "yòubiān", meaning: "right" },
  135: { character: "鱼", pinyin: "yú", meaning: "fish" },
  136: { character: "远", pinyin: "yuǎn", meaning: "Far" },
  137: { character: "运动", pinyin: "yùndòng", meaning: "sports" },
  138: { character: "再", pinyin: "zài", meaning: "Again" },
  139: { character: "早上", pinyin: "zǎoshang", meaning: "Morning" },
  140: { character: "丈夫", pinyin: "zhàngfū", meaning: "husband" },
  141: { character: "找", pinyin: "zhǎo", meaning: "Find" },
  142: { character: "着", pinyin: "zhe", meaning: "writing" },
  143: { character: "真", pinyin: "zhēn", meaning: "real" },
  144: { character: "正在", pinyin: "zhèng zài", meaning: "is" },
  145: { character: "知道", pinyin: "zhīdào", meaning: "Know" },
  146: { character: "准备", pinyin: "zhǔnbèi", meaning: "Prepare" },
  147: { character: "走", pinyin: "zǒu", meaning: "Walk" },
  148: { character: "最", pinyin: "zuì", meaning: "most" },
  149: { character: "左边", pinyin: "zuǒbiān", meaning: "left" },
};

const hsk3Dict = {
  0: { character: "阿姨", pinyin: "Āyí", meaning: "Aunt" },
  1: { character: "啊", pinyin: "a", meaning: "what" },
  2: { character: "矮", pinyin: "ǎi", meaning: "short" },
  3: { character: "爱好", pinyin: "àihào", meaning: "Hobby" },
  4: { character: "安静", pinyin: "ānjìng", meaning: "Quiet" },
  5: { character: "把", pinyin: "bǎ", meaning: "Bundle" },
  6: { character: "班", pinyin: "bān", meaning: "class" },
  7: { character: "搬", pinyin: "bān", meaning: "move" },
  8: { character: "办法", pinyin: "bànfǎ", meaning: "Method" },
  9: { character: "办公室", pinyin: "bàngōngshì", meaning: "office" },
  10: { character: "半", pinyin: "bàn", meaning: "Half" },
  11: { character: "帮忙", pinyin: "bāngmáng", meaning: "help" },
  12: { character: "包", pinyin: "bāo", meaning: "Bag" },
  13: { character: "饱", pinyin: "bǎo", meaning: "full" },
  14: { character: "北方", pinyin: "běifāng", meaning: "north" },
  15: { character: "被", pinyin: "bèi", meaning: "quilt" },
  16: { character: "鼻子", pinyin: "bízi", meaning: "nose" },
  17: { character: "比较", pinyin: "bǐjiào", meaning: "Compare" },
  18: { character: "比赛", pinyin: "bǐsài", meaning: "Contest" },
  19: { character: "笔记本", pinyin: "bǐjìběn", meaning: "notebook" },
  20: { character: "必须", pinyin: "bìxū", meaning: "must" },
  21: { character: "变化", pinyin: "biànhuà", meaning: "Variety" },
  22: { character: "别人", pinyin: "biérén", meaning: "other people" },
  23: { character: "冰箱", pinyin: "bīngxiāng", meaning: "refrigerator" },
  24: {
    character: "不但……而且……",
    pinyin: "bùdàn……érqiě……",
    meaning: "not only but also……",
  },
  25: { character: "菜单", pinyin: "càidān", meaning: "menu" },
  26: { character: "参加", pinyin: "cānjiā", meaning: "join" },
  27: { character: "草", pinyin: "cǎo", meaning: "Grass" },
  28: { character: "层", pinyin: "céng", meaning: "Floor" },
  29: { character: "差", pinyin: "chà", meaning: "Difference" },
  30: { character: "超市", pinyin: "chāoshì", meaning: "supermarket" },
  31: { character: "衬衫", pinyin: "chènshān", meaning: "shirt" },
  32: { character: "成绩", pinyin: "chéngjī", meaning: "score" },
  33: { character: "城市", pinyin: "chéngshì", meaning: "City" },
  34: { character: "迟到", pinyin: "chídào", meaning: "be late" },
  35: { character: "除了", pinyin: "chúle", meaning: "Apart from" },
  36: { character: "船", pinyin: "chuán", meaning: "ferry" },
  37: { character: "春", pinyin: "chūn", meaning: "spring" },
  38: { character: "词典", pinyin: "cídiǎn", meaning: "dictionary" },
  39: { character: "聪明", pinyin: "cōngmíng", meaning: "clever" },
  40: { character: "打扫", pinyin: "dǎsǎo", meaning: "clean" },
  41: { character: "打算", pinyin: "dǎsuàn", meaning: "Intend, plan" },
  42: { character: "带", pinyin: "dài", meaning: "bring" },
  43: { character: "担心", pinyin: "dānxīn", meaning: "Worry" },
  44: { character: "蛋糕", pinyin: "dàngāo", meaning: "cake" },
  45: { character: "当然", pinyin: "dāngrán", meaning: "certainly" },
  46: {
    character: "地（助词）",
    pinyin: "de (zhùcí)",
    meaning: "ground (particle)",
  },
  47: { character: "灯", pinyin: "dēng", meaning: "lamp" },
  48: { character: "地方", pinyin: "dìfāng", meaning: "place" },
  49: { character: "地铁", pinyin: "dìtiě", meaning: "subway" },
  50: { character: "地图", pinyin: "dìtú", meaning: "map" },
  51: { character: "电梯", pinyin: "diàntī", meaning: "elevator" },
  52: { character: "电子邮件", pinyin: "diànzǐ yóujiàn", meaning: "e-mail" },
  53: { character: "东", pinyin: "dōng", meaning: "East" },
  54: { character: "冬", pinyin: "dōng", meaning: "winter" },
  55: { character: "动物", pinyin: "dòngwù", meaning: "animal" },
  56: { character: "短", pinyin: "duǎn", meaning: "short" },
  57: { character: "段", pinyin: "duàn", meaning: "part" },
  58: { character: "锻炼", pinyin: "duànliàn", meaning: "exercise" },
  59: { character: "多么", pinyin: "duōme", meaning: "how" },
  60: { character: "饿", pinyin: "è", meaning: "hungry" },
  61: { character: "耳朵", pinyin: "ěrduǒ", meaning: "ear" },
  62: { character: "发", pinyin: "fā", meaning: "send" },
  63: { character: "发烧", pinyin: "fāshāo", meaning: "fever" },
  64: { character: "发现", pinyin: "fāxiàn", meaning: "Find" },
  65: { character: "方便", pinyin: "fāngbiàn", meaning: "convenient" },
  66: { character: "放", pinyin: "fàng", meaning: "put" },
  67: { character: "放心", pinyin: "fàngxīn", meaning: "rest assured" },
  68: { character: "分", pinyin: "fēn", meaning: "Minute" },
  69: { character: "附近", pinyin: "fùjìn", meaning: "nearby" },
  70: { character: "复习", pinyin: "fùxí", meaning: "review" },
  71: { character: "干净", pinyin: "gānjìng", meaning: "clean" },
  72: { character: "感冒", pinyin: "gǎnmào", meaning: "cold" },
  73: { character: "感兴趣", pinyin: "gǎn xìngqù", meaning: "interested" },
  74: { character: "刚才", pinyin: "gāngcái", meaning: "Just now" },
  75: { character: "个子", pinyin: "gèzi", meaning: "stature" },
  76: { character: "根据", pinyin: "gēnjù", meaning: "according to" },
  77: { character: "跟", pinyin: "gēn", meaning: "and" },
  78: { character: "更", pinyin: "gèng", meaning: "Even" },
  79: { character: "公斤", pinyin: "gōngjīn", meaning: "Kilogram" },
  80: { character: "公园", pinyin: "gōngyuán", meaning: "garden" },
  81: { character: "故事", pinyin: "gùshì", meaning: "story" },
  82: { character: "刮风", pinyin: "Guā fēng", meaning: "windy" },
  83: { character: "关", pinyin: "guān", meaning: "close" },
  84: { character: "关系", pinyin: "guānxì", meaning: "relation" },
  85: { character: "关心", pinyin: "guānxīn", meaning: "care" },
  86: { character: "关于", pinyin: "guānyú", meaning: "about" },
  87: { character: "国家", pinyin: "guó jiā", meaning: "nation" },
  88: {
    character: "过（动词）",
    pinyin: "guò (dòngcí)",
    meaning: "pass (verb)",
  },
  89: { character: "过去", pinyin: "guò qù", meaning: "past" },
  90: { character: "还是", pinyin: "háishì", meaning: "still" },
  91: { character: "害怕", pinyin: "hàipà", meaning: "Fear" },
  92: { character: "黑板", pinyin: "hēibǎn", meaning: "blackboard" },
  93: { character: "后来", pinyin: "hòulái", meaning: "later" },
  94: { character: "护照", pinyin: "hùzhào", meaning: "passport" },
  95: {
    character: "花（名词）",
    pinyin: "huā (míngcí)",
    meaning: "flower (noun)",
  },
  96: {
    character: "花（动词）",
    pinyin: "huā (dòngcí)",
    meaning: "flower (verb)",
  },
  97: { character: "画", pinyin: "huà", meaning: "painting" },
  98: { character: "坏", pinyin: "huài", meaning: "Bad" },
  99: { character: "欢迎", pinyin: "huānyíng", meaning: "welcome" },
  100: {
    character: "还（动词）",
    pinyin: "hái (dòngcí)",
    meaning: "also (verb)",
  },
  101: { character: "环境", pinyin: "huánjìng", meaning: "surroundings" },
  102: { character: "换", pinyin: "huàn", meaning: "Change" },
  103: { character: "黄河", pinyin: "huánghé", meaning: "Yellow River" },
  104: { character: "回答", pinyin: "huídá", meaning: "Reply" },
  105: { character: "会议", pinyin: "huìyì", meaning: "Meeting" },
  106: { character: "或者", pinyin: "huòzhě", meaning: "or" },
  107: { character: "几乎", pinyin: "jīhū", meaning: "almost" },
  108: { character: "机会", pinyin: "jīhuì", meaning: "Chance" },
  109: { character: "极", pinyin: "jí", meaning: "pole, utmost" },
  110: { character: "记得", pinyin: "jìdé", meaning: "Remember" },
  111: { character: "季节", pinyin: "jìjié", meaning: "season" },
  112: { character: "检查", pinyin: "jiǎnchá", meaning: "an examination" },
  113: { character: "简单", pinyin: "jiǎndān", meaning: "Simple" },
  114: { character: "见面", pinyin: "jiànmiàn", meaning: "Meet" },
  115: { character: "健康", pinyin: "jiànkāng", meaning: "healthy" },
  116: { character: "讲", pinyin: "jiǎng", meaning: "speak" },
  117: { character: "教", pinyin: "jiào", meaning: "teach" },
  118: { character: "角", pinyin: "jiǎo", meaning: "horn" },
  119: { character: "脚", pinyin: "jiǎo", meaning: "foot" },
  120: { character: "接", pinyin: "jiē", meaning: "catch" },
  121: { character: "街道", pinyin: "jiēdào", meaning: "street" },
  122: { character: "节目", pinyin: "jiémù", meaning: "programme" },
  123: { character: "节日", pinyin: "jiérì", meaning: "festival" },
  124: { character: "结婚", pinyin: "jiéhūn", meaning: "marry" },
  125: { character: "结束", pinyin: "jiéshù", meaning: "Finish" },
  126: { character: "解决", pinyin: "jiějué", meaning: "solve" },
  127: { character: "借", pinyin: "jiè", meaning: "borrow" },
  128: { character: "经常", pinyin: "jīngcháng", meaning: "often" },
  129: { character: "经过", pinyin: "jīngguò", meaning: "go through" },
  130: { character: "经理", pinyin: "jīnglǐ", meaning: "manager" },
  131: { character: "久", pinyin: "jiǔ", meaning: "Long" },
  132: { character: "旧", pinyin: "jiù", meaning: "old" },
  133: { character: "句子", pinyin: "jùzi", meaning: "sentence" },
  134: { character: "决定", pinyin: "juédìng", meaning: "Decide" },
  135: { character: "可爱", pinyin: "kě'ài", meaning: "cute" },
  136: { character: "渴", pinyin: "kě", meaning: "thirsty" },
  137: { character: "刻", pinyin: "kè", meaning: "carve" },
  138: { character: "客人", pinyin: "kèrén", meaning: "guest" },
  139: { character: "空调", pinyin: "kòngtiáo", meaning: "air conditioner" },
  140: { character: "口", pinyin: "kǒu", meaning: "mouth" },
  141: { character: "哭", pinyin: "kū", meaning: "cry" },
  142: { character: "裤子", pinyin: "kùzi", meaning: "Pants" },
  143: { character: "筷子", pinyin: "kuàizi", meaning: "Chopsticks" },
  144: { character: "蓝", pinyin: "lán", meaning: "blue" },
  145: { character: "老", pinyin: "lǎo", meaning: "old" },
  146: { character: "离开", pinyin: "líkāi", meaning: "leave" },
  147: { character: "礼物", pinyin: "lǐwù", meaning: "Gift, present" },
  148: { character: "历史", pinyin: "lìshǐ", meaning: "history" },
  149: { character: "脸", pinyin: "liǎn", meaning: "Face" },
  150: { character: "练习", pinyin: "liànxí", meaning: "practise" },
  151: { character: "辆", pinyin: "liàng", meaning: "vehicle" },
  152: { character: "聊天", pinyin: "liáotiān", meaning: "to chat with" },
  153: { character: "了解", pinyin: "liǎojiě", meaning: "learn" },
  154: { character: "邻居", pinyin: "línjū", meaning: "Neighbor" },
  155: { character: "留学", pinyin: "liúxué", meaning: "study abroad" },
  156: { character: "楼", pinyin: "lóu", meaning: "building" },
  157: { character: "绿", pinyin: "lǜ", meaning: "green" },
  158: { character: "马", pinyin: "mǎ", meaning: "horse" },
  159: { character: "马上", pinyin: "mǎshàng", meaning: "immediately" },
  160: { character: "满意", pinyin: "mǎnyì", meaning: "satisfy" },
  161: { character: "帽子", pinyin: "màozi", meaning: "hat" },
  162: { character: "米", pinyin: "mǐ", meaning: "Meter" },
  163: { character: "面包", pinyin: "Miànbāo", meaning: "bread" },
  164: { character: "明白", pinyin: "míngbái", meaning: "clear" },
  165: { character: "拿", pinyin: "ná", meaning: "take" },
  166: { character: "奶奶", pinyin: "nǎinai", meaning: "grandmother" },
  167: { character: "南", pinyin: "nán", meaning: "South" },
  168: { character: "难", pinyin: "nán", meaning: "Disaster" },
  169: { character: "难过", pinyin: "nánguò", meaning: "sad" },
  170: { character: "年级", pinyin: "niánjí", meaning: "grade" },
  171: { character: "年轻", pinyin: "niánqīng", meaning: "young" },
  172: { character: "鸟", pinyin: "niǎo", meaning: "bird" },
  173: { character: "努力", pinyin: "nǔlì", meaning: "Great effort" },
  174: { character: "爬山", pinyin: "páshān", meaning: "climb mountains" },
  175: { character: "盘子", pinyin: "pánzi", meaning: "plate" },
  176: { character: "胖", pinyin: "pàng", meaning: "fat" },
  177: { character: "皮鞋", pinyin: "píxié", meaning: "leather shoes" },
  178: { character: "啤酒", pinyin: "píjiǔ", meaning: "beer" },
  179: { character: "瓶子", pinyin: "píngzi", meaning: "bottle" },
  180: { character: "其实", pinyin: "qíshí", meaning: "actually" },
  181: { character: "其他", pinyin: "qítā", meaning: "other" },
  182: { character: "奇怪", pinyin: "qíguài", meaning: "strangeness" },
  183: { character: "骑", pinyin: "qí", meaning: "ride" },
  184: { character: "起飞", pinyin: "qǐfēi", meaning: "take off" },
  185: { character: "起来", pinyin: "qǐlái", meaning: "stand up" },
  186: { character: "清楚", pinyin: "qīngchǔ", meaning: "clear" },
  187: { character: "请假", pinyin: "qǐngjià", meaning: "Ask for leave" },
  188: { character: "秋", pinyin: "qiū", meaning: "Autumn" },
  189: { character: "裙子", pinyin: "qúnzi", meaning: "skirt" },
  190: { character: "然后", pinyin: "ránhòu", meaning: "Then" },
  191: { character: "热情", pinyin: "rèqíng", meaning: "enthusiasm" },
  192: { character: "认为", pinyin: "rènwéi", meaning: "think" },
  193: { character: "认真", pinyin: "rènzhēn", meaning: "serious" },
  194: { character: "容易", pinyin: "róngyì", meaning: "easy" },
  195: { character: "如果", pinyin: "rúguǒ", meaning: "if" },
  196: { character: "伞", pinyin: "sǎn", meaning: "umbrella" },
  197: { character: "上网", pinyin: "shàngwǎng", meaning: "go online" },
  198: { character: "生气", pinyin: "shēngqì", meaning: "angry" },
  199: { character: "声音", pinyin: "shēngyīn", meaning: "sound" },
  200: { character: "世界", pinyin: "shìjiè", meaning: "world" },
  201: { character: "试", pinyin: "shì", meaning: "try" },
  202: { character: "瘦", pinyin: "shòu", meaning: "thin" },
  203: { character: "叔叔", pinyin: "shūshu", meaning: "uncle" },
  204: { character: "舒服", pinyin: "shūfú", meaning: "Comfortable" },
  205: { character: "树", pinyin: "shù", meaning: "Tree" },
  206: { character: "数学", pinyin: "shùxué", meaning: "math" },
  207: { character: "刷牙", pinyin: "shuāyá", meaning: "brush teeth" },
  208: { character: "双", pinyin: "shuāng", meaning: "pair" },
  209: { character: "水平", pinyin: "shuǐpíng", meaning: "Level" },
  210: { character: "司机", pinyin: "sījī", meaning: "driver" },
  211: { character: "太阳", pinyin: "tàiyáng", meaning: "sun" },
  212: { character: "特别", pinyin: "tèbié", meaning: "special" },
  213: { character: "疼", pinyin: "téng", meaning: "pain" },
  214: { character: "提高", pinyin: "tígāo", meaning: "improve" },
  215: { character: "体育", pinyin: "tǐyù", meaning: "physical education" },
  216: { character: "甜", pinyin: "tián", meaning: "sweet" },
  217: { character: "条", pinyin: "tiáo", meaning: "strip" },
  218: { character: "同事", pinyin: "tóngshì", meaning: "colleague" },
  219: { character: "同意", pinyin: "tóngyì", meaning: "agree" },
  220: { character: "头发", pinyin: "tóufǎ", meaning: "hair" },
  221: { character: "突然", pinyin: "túrán", meaning: "Sudden" },
  222: { character: "图书馆", pinyin: "túshū guǎn", meaning: "library" },
  223: { character: "腿", pinyin: "tuǐ", meaning: "leg" },
  224: { character: "完成", pinyin: "wánchéng", meaning: "Finish" },
  225: { character: "碗", pinyin: "wǎn", meaning: "bowl" },
  226: { character: "万", pinyin: "wàn", meaning: "Ten thousand" },
  227: { character: "忘记", pinyin: "wàngjì", meaning: "forget" },
  228: { character: "为", pinyin: "wèi", meaning: "for" },
  229: { character: "为了", pinyin: "wèile", meaning: "for" },
  230: { character: "位", pinyin: "wèi", meaning: "bit" },
  231: { character: "文化", pinyin: "wénhuà", meaning: "culture" },
  232: { character: "西", pinyin: "xī", meaning: "West" },
  233: { character: "习惯", pinyin: "xíguàn", meaning: "Habit" },
  234: { character: "洗手间", pinyin: "xǐshǒujiān", meaning: "Toilet" },
  235: { character: "洗澡", pinyin: "xǐzǎo", meaning: "bath" },
  236: { character: "夏", pinyin: "xià", meaning: "summer" },
  237: { character: "先", pinyin: "xiān", meaning: "First" },
  238: { character: "相信", pinyin: "xiāngxìn", meaning: "Believe" },
  239: { character: "香蕉", pinyin: "xiāngjiāo", meaning: "banana" },
  240: { character: "向", pinyin: "xiàng", meaning: "Towards" },
  241: { character: "像", pinyin: "xiàng", meaning: "picture" },
  242: { character: "小心", pinyin: "xiǎoxīn", meaning: "Be careful" },
  243: { character: "校长", pinyin: "xiàozhǎng", meaning: "headmaster" },
  244: { character: "新闻", pinyin: "xīnwén", meaning: "news" },
  245: { character: "新鲜", pinyin: "xīnxiān", meaning: "Fresh" },
  246: { character: "信用卡", pinyin: "xìnyòngkǎ", meaning: "credit card" },
  247: { character: "行李箱", pinyin: "xínglǐ xiāng", meaning: "suitcase" },
  248: { character: "熊猫", pinyin: "xióngmāo", meaning: "panda" },
  249: { character: "需要", pinyin: "xūyào", meaning: "need" },
  250: { character: "选择", pinyin: "xuǎnzé", meaning: "choose" },
  251: { character: "要求", pinyin: "yāoqiú", meaning: "Require" },
  252: { character: "爷爷", pinyin: "yéyé", meaning: "grandfather" },
  253: { character: "一般", pinyin: "yībān", meaning: "generally" },
  254: { character: "一边", pinyin: "yībiān", meaning: "side" },
  255: { character: "一定", pinyin: "yīdìng", meaning: "must" },
  256: { character: "一共", pinyin: "yīgòng", meaning: "total" },
  257: { character: "一会儿", pinyin: "yīhuǐ'er", meaning: "a while" },
  258: { character: "一样", pinyin: "yīyàng", meaning: "Same" },
  259: { character: "一直", pinyin: "yīzhí", meaning: "Always" },
  260: { character: "以前", pinyin: "yǐqián", meaning: "before" },
  261: { character: "音乐", pinyin: "yīnyuè", meaning: "music" },
  262: { character: "银行", pinyin: "yínháng", meaning: "bank" },
  263: { character: "饮料", pinyin: "yǐnliào", meaning: "drinks" },
  264: { character: "应该", pinyin: "yīnggāi", meaning: "should" },
  265: { character: "影响", pinyin: "yǐngxiǎng", meaning: "influences" },
  266: { character: "用", pinyin: "yòng", meaning: "use" },
  267: { character: "游戏", pinyin: "yóuxì", meaning: "game" },
  268: { character: "有名", pinyin: "yǒumíng", meaning: "famous" },
  269: { character: "又", pinyin: "yòu", meaning: "again" },
  270: { character: "遇到", pinyin: "yù dào", meaning: "meet" },
  271: { character: "元", pinyin: "yuán", meaning: "Yuan" },
  272: { character: "愿意", pinyin: "yuànyì", meaning: "willing" },
  273: { character: "月亮", pinyin: "yuèliàng", meaning: "moon" },
  274: { character: "越", pinyin: "yuè", meaning: "cross" },
  275: { character: "站", pinyin: "zhàn", meaning: "stand" },
  276: { character: "张", pinyin: "zhāng", meaning: "open, long" },
  277: {
    character: "长（动词）",
    pinyin: "zhǎng (dòngcí)",
    meaning: "long (verb)",
  },
  278: { character: "着急", pinyin: "zhāojí", meaning: "to worry" },
  279: { character: "照顾", pinyin: "zhàogù", meaning: "take care of" },
  280: { character: "照片", pinyin: "zhàopiàn", meaning: "photo" },
  281: { character: "照相机", pinyin: "zhàoxiàngjī", meaning: "camera" },
  282: {
    character: "只（量词）",
    pinyin: "zhǐ (liàngcí)",
    meaning: "only (quantifier)",
  },
  283: {
    character: "只（副词）",
    pinyin: "zhǐ (fùcí)",
    meaning: "only (adverb)",
  },
  284: {
    character: "只有……才……",
    pinyin: "zhǐyǒu……cái……",
    meaning: "only... that...",
  },
  285: { character: "中间", pinyin: "zhōngjiān", meaning: "middle" },
  286: { character: "中文", pinyin: "zhōngwén", meaning: "Chinese" },
  287: { character: "终于", pinyin: "zhōngyú", meaning: "finally" },
  288: {
    character: "种（量词）",
    pinyin: "zhǒng (liàngcí)",
    meaning: "species (quantifier)",
  },
  289: { character: "重要", pinyin: "zhòngyào", meaning: "important" },
  290: { character: "周末", pinyin: "zhōumò", meaning: "weekend" },
  291: { character: "主要", pinyin: "zhǔyào", meaning: "main" },
  292: { character: "注意", pinyin: "zhùyì", meaning: "Notice" },
  293: { character: "自己", pinyin: "zìjǐ", meaning: "Own" },
  294: { character: "自行车", pinyin: "zìxíngchē", meaning: "bike" },
  295: { character: "总是", pinyin: "zǒng shì", meaning: "always" },
  296: { character: "嘴", pinyin: "zuǐ", meaning: "mouth" },
  297: { character: "最后", pinyin: "zuìhòu", meaning: "at last" },
  298: { character: "最近", pinyin: "zuìjìn", meaning: "recent" },
  299: { character: "作业", pinyin: "zuòyè", meaning: "Operation" },
};

const hsk4Dict = {
  0: { character: "爱情", pinyin: "àiqíng", meaning: "love" },
  1: { character: "安排", pinyin: "ānpái", meaning: "arrange" },
  2: { character: "安全", pinyin: "ānquán", meaning: "Safety" },
  3: { character: "按时", pinyin: "ànshí", meaning: "on time" },
  4: { character: "按照", pinyin: "ànzhào", meaning: "according to" },
  5: { character: "百分之", pinyin: "bǎi fēn zhī", meaning: "percent" },
  6: { character: "棒", pinyin: "bàng", meaning: "Great" },
  7: { character: "包子", pinyin: "bāozi", meaning: "steamed stuffed bun" },
  8: { character: "保护", pinyin: "bǎohù", meaning: "Protect" },
  9: { character: "保证", pinyin: "bǎozhèng", meaning: "ensure" },
  10: { character: "报名", pinyin: "bàomíng", meaning: "Sign up" },
  11: { character: "抱", pinyin: "bào", meaning: "hug" },
  12: { character: "抱歉", pinyin: "bàoqiàn", meaning: "Feel sorry" },
  13: { character: "倍", pinyin: "bèi", meaning: "times" },
  14: { character: "本来", pinyin: "běnlái", meaning: "originally" },
  15: { character: "笨", pinyin: "bèn", meaning: "stupid" },
  16: { character: "比如", pinyin: "bǐrú", meaning: "for example" },
  17: { character: "毕业", pinyin: "bìyè", meaning: "graduate" },
  18: { character: "遍", pinyin: "biàn", meaning: "all over" },
  19: { character: "标准", pinyin: "biāozhǔn", meaning: "standard" },
  20: { character: "表格", pinyin: "biǎogé", meaning: "sheet" },
  21: { character: "表示", pinyin: "biǎoshì", meaning: "express" },
  22: { character: "表演", pinyin: "biǎoyǎn", meaning: "Performance" },
  23: { character: "表扬", pinyin: "biǎoyáng", meaning: "praise" },
  24: { character: "饼干", pinyin: "bǐnggān", meaning: "biscuit" },
  25: { character: "并且", pinyin: "bìngqiě", meaning: "and" },
  26: { character: "博士", pinyin: "bóshì", meaning: "PhD" },
  27: { character: "不得不", pinyin: "bùdé bù", meaning: "have to" },
  28: { character: "不管", pinyin: "bùguǎn", meaning: "in spite of" },
  29: { character: "不过", pinyin: "bùguò", meaning: "but" },
  30: { character: "不仅", pinyin: "bùjǐn", meaning: "not only" },
  31: { character: "部分", pinyin: "bùfèn", meaning: "part" },
  32: { character: "擦", pinyin: "cā", meaning: "wipe" },
  33: { character: "猜", pinyin: "cāi", meaning: "Guess" },
  34: { character: "材料", pinyin: "cáiliào", meaning: "Material" },
  35: { character: "参观", pinyin: "cānguān", meaning: "visit" },
  36: { character: "餐厅", pinyin: "cāntīng", meaning: "Dining room" },
  37: { character: "厕所", pinyin: "cèsuǒ", meaning: "bathroom" },
  38: { character: "差不多", pinyin: "chàbùduō", meaning: "almost" },
  39: { character: "长城", pinyin: "chángchéng", meaning: "Great Wall" },
  40: { character: "长江", pinyin: "chángjiāng", meaning: "Yangtze" },
  41: { character: "尝", pinyin: "cháng", meaning: "taste" },
  42: { character: "场", pinyin: "chǎng", meaning: "field" },
  43: { character: "超过", pinyin: "chāoguò", meaning: "Exceed" },
  44: { character: "成功", pinyin: "chénggōng", meaning: "success" },
  45: { character: "成为", pinyin: "chéngwéi", meaning: "become" },
  46: { character: "诚实", pinyin: "chéngshí", meaning: "honest" },
  47: { character: "乘坐", pinyin: "chéngzuò", meaning: "ride" },
  48: { character: "吃惊", pinyin: "chījīng", meaning: "be surprised" },
  49: { character: "重新", pinyin: "chóngxīn", meaning: "again" },
  50: { character: "抽烟", pinyin: "chōuyān", meaning: "smokes" },
  51: { character: "出差", pinyin: "chūchāi", meaning: "On business trip" },
  52: { character: "出发", pinyin: "chūfā", meaning: "Set off" },
  53: { character: "出生", pinyin: "chūshēng", meaning: "born" },
  54: { character: "出现", pinyin: "chūxiàn", meaning: "Appear" },
  55: { character: "厨房", pinyin: "chúfáng", meaning: "kitchen" },
  56: { character: "传真", pinyin: "chuánzhēn", meaning: "fax" },
  57: { character: "窗户", pinyin: "chuānghù", meaning: "window" },
  58: { character: "词语", pinyin: "cíyǔ", meaning: "words" },
  59: { character: "从来", pinyin: "cónglái", meaning: "never" },
  60: { character: "粗心", pinyin: "cūxīn", meaning: "careless" },
  61: { character: "存", pinyin: "cún", meaning: "live" },
  62: { character: "错误", pinyin: "cuòwù", meaning: "mistake" },
  63: { character: "答案", pinyin: "dá'àn", meaning: "Answer" },
  64: { character: "打扮", pinyin: "dǎbàn", meaning: "dress up" },
  65: { character: "打扰", pinyin: "dǎrǎo", meaning: "disturb" },
  66: { character: "打印", pinyin: "dǎyìn", meaning: "Print" },
  67: { character: "打招呼", pinyin: "dǎzhāohū", meaning: "say hello" },
  68: { character: "打折", pinyin: "dǎzhé", meaning: "Discount" },
  69: { character: "打针", pinyin: "dǎzhēn", meaning: "inject" },
  70: { character: "大概", pinyin: "dàgài", meaning: "perhaps" },
  71: { character: "大使馆", pinyin: "dàshǐ guǎn", meaning: "embassy" },
  72: { character: "大约", pinyin: "dàyuē", meaning: "About" },
  73: { character: "大夫", pinyin: "dàfū", meaning: "doctor" },
  74: { character: "戴", pinyin: "dài", meaning: "wore" },
  75: { character: "当", pinyin: "dāng", meaning: "when" },
  76: { character: "当时", pinyin: "dāngshí", meaning: "then" },
  77: { character: "刀", pinyin: "dāo", meaning: "Knife" },
  78: { character: "导游", pinyin: "dǎoyóu", meaning: "Tourist guide" },
  79: { character: "到处", pinyin: "dàochù", meaning: "everywhere" },
  80: { character: "到底", pinyin: "dàodǐ", meaning: "in the end" },
  81: { character: "倒", pinyin: "dào", meaning: "fall" },
  82: { character: "道歉", pinyin: "dàoqiàn", meaning: "Apologize" },
  83: { character: "得意", pinyin: "déyì", meaning: "proud" },
  84: {
    character: "得（助动词）",
    pinyin: "dé (zhùdòngcí)",
    meaning: "get (auxiliary verb)",
  },
  85: { character: "登机牌", pinyin: "dēng jī pái", meaning: "boarding pass" },
  86: {
    character: "等（助词）",
    pinyin: "děng (zhùcí)",
    meaning: "wait (particle)",
  },
  87: { character: "低", pinyin: "dī", meaning: "Low" },
  88: { character: "底", pinyin: "dǐ", meaning: "end" },
  89: { character: "地点", pinyin: "dìdiǎn", meaning: "Place" },
  90: { character: "地球", pinyin: "dìqiú", meaning: "Earth" },
  91: { character: "地址", pinyin: "dìzhǐ", meaning: "address" },
  92: { character: "调查", pinyin: "diàochá", meaning: "investigation" },
  93: { character: "掉", pinyin: "diào", meaning: "Lose" },
  94: { character: "丢", pinyin: "diū", meaning: "leave" },
  95: { character: "动作", pinyin: "dòngzuò", meaning: "action" },
  96: { character: "堵车", pinyin: "dǔchē", meaning: "traffic jam" },
  97: { character: "肚子", pinyin: "dùzi", meaning: "abdomen" },
  98: { character: "短信", pinyin: "duǎnxìn", meaning: "Short message" },
  99: { character: "对话", pinyin: "duìhuà", meaning: "dialogue" },
  100: { character: "对面", pinyin: "duìmiàn", meaning: "opposite" },
  101: { character: "对于", pinyin: "duìyú", meaning: "for" },
  102: { character: "儿童", pinyin: "értóng", meaning: "child" },
  103: { character: "而", pinyin: "ér", meaning: "and" },
  104: { character: "发生", pinyin: "fāshēng", meaning: "occur" },
  105: { character: "发展", pinyin: "fāzhǎn", meaning: "develop" },
  106: { character: "法律", pinyin: "fǎlǜ", meaning: "law" },
  107: { character: "翻译", pinyin: "fānyì", meaning: "translate" },
  108: { character: "烦恼", pinyin: "fánnǎo", meaning: "trouble" },
  109: { character: "反对", pinyin: "fǎnduì", meaning: "be opposed to" },
  110: { character: "方法", pinyin: "fāngfǎ", meaning: "method" },
  111: { character: "方面", pinyin: "fāngmiàn", meaning: "aspect" },
  112: { character: "方向", pinyin: "fāngxiàng", meaning: "direction" },
  113: { character: "房东", pinyin: "fángdōng", meaning: "landlord" },
  114: { character: "放弃", pinyin: "fàngqì", meaning: "give up" },
  115: {
    character: "放暑假",
    pinyin: "fàng shǔjià",
    meaning: "summer holiday",
  },
  116: { character: "放松", pinyin: "fàngsōng", meaning: "Relax" },
  117: { character: "份", pinyin: "fèn", meaning: "share" },
  118: { character: "丰富", pinyin: "fēngfù", meaning: "Rich" },
  119: { character: "否则", pinyin: "fǒuzé", meaning: "otherwise" },
  120: { character: "符合", pinyin: "fúhé", meaning: "meets the" },
  121: { character: "父亲", pinyin: "fùqīn", meaning: "Father" },
  122: { character: "付款", pinyin: "fùkuǎn", meaning: "Payment" },
  123: { character: "负责", pinyin: "fùzé", meaning: "Responsible" },
  124: { character: "复印", pinyin: "fùyìn", meaning: "copy" },
  125: { character: "复杂", pinyin: "fùzá", meaning: "complex" },
  126: { character: "富", pinyin: "fù", meaning: "rich" },
  127: { character: "改变", pinyin: "gǎibiàn", meaning: "Change" },
  128: { character: "干杯", pinyin: "gānbēi", meaning: "cheers" },
  129: { character: "赶", pinyin: "gǎn", meaning: "rush" },
  130: { character: "敢", pinyin: "gǎn", meaning: "dare" },
  131: { character: "感动", pinyin: "gǎndòng", meaning: "move" },
  132: { character: "感觉", pinyin: "gǎnjué", meaning: "Feel" },
  133: { character: "感情", pinyin: "gǎnqíng", meaning: "emotion" },
  134: { character: "感谢", pinyin: "gǎnxiè", meaning: "grateful" },
  135: { character: "干", pinyin: "gàn", meaning: "Dry" },
  136: { character: "刚", pinyin: "gāng", meaning: "Just" },
  137: { character: "高速公路", pinyin: "gāosù gōnglù", meaning: "highway" },
  138: { character: "胳膊", pinyin: "gēbó", meaning: "arm" },
  139: { character: "各", pinyin: "gè", meaning: "each" },
  140: { character: "工资", pinyin: "gōngzī", meaning: "salary" },
  141: { character: "公里", pinyin: "gōnglǐ", meaning: "kilometer" },
  142: { character: "功夫", pinyin: "gōngfū", meaning: "effort" },
  143: { character: "共同", pinyin: "gòngtóng", meaning: "common" },
  144: { character: "购物", pinyin: "gòuwù", meaning: "Shopping" },
  145: { character: "够", pinyin: "gòu", meaning: "enough" },
  146: { character: "估计", pinyin: "gūjì", meaning: "estimate" },
  147: { character: "鼓励", pinyin: "gǔlì", meaning: "encourage" },
  148: { character: "故意", pinyin: "gùyì", meaning: "deliberately" },
  149: { character: "顾客", pinyin: "gùkè", meaning: "customer" },
  150: { character: "挂", pinyin: "guà", meaning: "hang" },
  151: { character: "关键", pinyin: "guānjiàn", meaning: "The essential" },
  152: { character: "观众", pinyin: "guānzhòng", meaning: "audience" },
  153: { character: "管理", pinyin: "guǎnlǐ", meaning: "manage" },
  154: { character: "光", pinyin: "guāng", meaning: "Light" },
  155: { character: "广播", pinyin: "guǎngbò", meaning: "broadcast" },
  156: { character: "广告", pinyin: "guǎnggào", meaning: "advertise" },
  157: { character: "逛", pinyin: "guàng", meaning: "visit" },
  158: { character: "规定", pinyin: "guīdìng", meaning: "Regulation" },
  159: {
    character: "国籍",
    pinyin: "guójí",
    meaning: "Country of Citizenship",
  },
  160: { character: "国际", pinyin: "guójì", meaning: "internationality" },
  161: { character: "果汁", pinyin: "guǒ zhī", meaning: "juice" },
  162: { character: "过程", pinyin: "guòchéng", meaning: "process" },
  163: { character: "海洋", pinyin: "hǎiyáng", meaning: "ocean" },
  164: { character: "害羞", pinyin: "hàixiū", meaning: "shy" },
  165: { character: "寒假", pinyin: "hánjià", meaning: "winter vacation" },
  166: { character: "汗", pinyin: "hàn", meaning: "sweat" },
  167: { character: "航班", pinyin: "hángbān", meaning: "flight" },
  168: { character: "好处", pinyin: "hǎochù", meaning: "benefit" },
  169: { character: "好像", pinyin: "hǎoxiàng", meaning: "like" },
  170: { character: "号码", pinyin: "hàomǎ", meaning: "Number" },
  171: { character: "合格", pinyin: "hégé", meaning: "qualified" },
  172: { character: "合适", pinyin: "héshì", meaning: "Suitable" },
  173: { character: "盒子", pinyin: "hézi", meaning: "Box" },
  174: { character: "后悔", pinyin: "hòuhuǐ", meaning: "regret" },
  175: { character: "厚", pinyin: "hòu", meaning: "thick" },
  176: { character: "互联网", pinyin: "hùliánwǎng", meaning: "the Internet" },
  177: { character: "互相", pinyin: "hùxiāng", meaning: "each other" },
  178: { character: "护士", pinyin: "hùshì", meaning: "Nurse" },
  179: { character: "怀疑", pinyin: "huáiyí", meaning: "Suspect" },
  180: { character: "回忆", pinyin: "huíyì", meaning: "Memories" },
  181: { character: "活动", pinyin: "huódòng", meaning: "Activity" },
  182: { character: "活泼", pinyin: "huópō", meaning: "Lively" },
  183: { character: "火", pinyin: "huǒ", meaning: "fire" },
  184: { character: "获得", pinyin: "huòdé", meaning: "get" },
  185: { character: "积极", pinyin: "jījí", meaning: "positive" },
  186: { character: "积累", pinyin: "jīlěi", meaning: "accumulation" },
  187: { character: "基础", pinyin: "jīchǔ", meaning: "Base" },
  188: { character: "激动", pinyin: "jīdòng", meaning: "Excited" },
  189: { character: "及时", pinyin: "jíshí", meaning: "timely" },
  190: { character: "即使", pinyin: "jíshǐ", meaning: "even though" },
  191: { character: "计划", pinyin: "jìhuà", meaning: "plan" },
  192: { character: "记者", pinyin: "jìzhě", meaning: "reporter" },
  193: { character: "技术", pinyin: "jìshù", meaning: "technology" },
  194: { character: "既然", pinyin: "jìrán", meaning: "now that" },
  195: { character: "继续", pinyin: "jìxù", meaning: "continue" },
  196: { character: "寄", pinyin: "jì", meaning: "send" },
  197: { character: "加班", pinyin: "jiābān", meaning: "work overtime" },
  198: { character: "加油站", pinyin: "jiāyóu zhàn", meaning: "Gas station" },
  199: { character: "家具", pinyin: "jiājù", meaning: "furniture" },
  200: { character: "假", pinyin: "jiǎ", meaning: "Fake" },
  201: { character: "价格", pinyin: "jiàgé", meaning: "price" },
  202: { character: "坚持", pinyin: "jiānchí", meaning: "persist in" },
  203: { character: "减肥", pinyin: "jiǎnféi", meaning: "lose weight" },
  204: { character: "减少", pinyin: "jiǎnshǎo", meaning: "reduce" },
  205: { character: "建议", pinyin: "jiànyì", meaning: "Suggest" },
  206: { character: "将来", pinyin: "jiānglái", meaning: "future" },
  207: { character: "奖金", pinyin: "jiǎngjīn", meaning: "bonus" },
  208: { character: "降低", pinyin: "jiàngdī", meaning: "reduce" },
  209: { character: "降落", pinyin: "jiàngluò", meaning: "landing" },
  210: { character: "交", pinyin: "jiāo", meaning: "pay" },
  211: { character: "交流", pinyin: "jiāoliú", meaning: "comminicate" },
  212: { character: "交通", pinyin: "jiāotōng", meaning: "transportation" },
  213: { character: "郊区", pinyin: "jiāoqū", meaning: "suburbs" },
  214: { character: "骄傲", pinyin: "jiāo'ào", meaning: "pride" },
  215: { character: "饺子", pinyin: "jiǎozi", meaning: "dumpling" },
  216: { character: "教授", pinyin: "jiàoshòu", meaning: "professor" },
  217: { character: "教育", pinyin: "jiàoyù", meaning: "educate" },
  218: { character: "接受", pinyin: "jiēshòu", meaning: "accept" },
  219: { character: "接着", pinyin: "jiēzhe", meaning: "then" },
  220: { character: "节", pinyin: "jié", meaning: "Festival" },
  221: { character: "节约", pinyin: "jiéyuē", meaning: "saving" },
  222: { character: "结果", pinyin: "jiéguǒ", meaning: "result" },
  223: { character: "解释", pinyin: "jiěshì", meaning: "explain" },
  224: { character: "尽管", pinyin: "jǐnguǎn", meaning: "although" },
  225: { character: "紧张", pinyin: "jǐnzhāng", meaning: "nervous" },
  226: { character: "进行", pinyin: "Jìnxíng", meaning: "conduct" },
  227: { character: "禁止", pinyin: "jìnzhǐ", meaning: "prohibit" },
  228: { character: "京剧", pinyin: "jīngjù", meaning: "Peking Opera" },
  229: { character: "经济", pinyin: "jīngjì", meaning: "economy" },
  230: { character: "经历", pinyin: "jīnglì", meaning: "experience" },
  231: { character: "经验", pinyin: "jīngyàn", meaning: "experience" },
  232: { character: "精彩", pinyin: "jīngcǎi", meaning: "Wonderful" },
  233: { character: "景色", pinyin: "jǐngsè", meaning: "scenery" },
  234: { character: "警察", pinyin: "jǐngchá", meaning: "police" },
  235: { character: "竞争", pinyin: "jìngzhēng", meaning: "compete" },
  236: { character: "竟然", pinyin: "jìngrán", meaning: "it turns out" },
  237: { character: "镜子", pinyin: "jìngzi", meaning: "Mirror" },
  238: { character: "究竟", pinyin: "jiùjìng", meaning: "after all" },
  239: { character: "举", pinyin: "jǔ", meaning: "lift" },
  240: { character: "举办", pinyin: "jǔbàn", meaning: "hold" },
  241: { character: "举行", pinyin: "jǔxíng", meaning: "held" },
  242: { character: "拒绝", pinyin: "jùjué", meaning: "reject" },
  243: { character: "距离", pinyin: "jùlí", meaning: "distance" },
  244: { character: "聚会", pinyin: "jùhuì", meaning: "reunion" },
  245: { character: "开玩笑", pinyin: "kāiwánxiào", meaning: "joke" },
  246: { character: "开心", pinyin: "kāixīn", meaning: "happy" },
  247: { character: "看法", pinyin: "kànfǎ", meaning: "view" },
  248: { character: "考虑", pinyin: "kǎolǜ", meaning: "consider" },
  249: { character: "烤鸭", pinyin: "kǎoyā", meaning: "Roast Duck" },
  250: { character: "科学", pinyin: "kēxué", meaning: "science" },
  251: { character: "棵", pinyin: "kē", meaning: "tree" },
  252: { character: "咳嗽", pinyin: "késòu", meaning: "cough" },
  253: { character: "可怜", pinyin: "kělián", meaning: "Pitiful" },
  254: { character: "可是", pinyin: "kěshì", meaning: "But" },
  255: { character: "可惜", pinyin: "kěxí", meaning: "pity" },
  256: { character: "客厅", pinyin: "kètīng", meaning: "living room" },
  257: { character: "肯定", pinyin: "kěndìng", meaning: "affim" },
  258: { character: "空", pinyin: "kōng", meaning: "null" },
  259: { character: "空气", pinyin: "kōngqì", meaning: "Air" },
  260: { character: "恐怕", pinyin: "kǒngpà", meaning: "I am afraid" },
  261: { character: "苦", pinyin: "kǔ", meaning: "bitter" },
  262: {
    character: "矿泉水",
    pinyin: "kuàngquán shuǐ",
    meaning: "mineral water",
  },
  263: { character: "困", pinyin: "kùn", meaning: "Sleepy" },
  264: { character: "困难", pinyin: "kùnnán", meaning: "difficulty" },
  265: { character: "垃圾桶", pinyin: "lèsè tǒng", meaning: "garbage can" },
  266: { character: "拉", pinyin: "lā", meaning: "pull" },
  267: { character: "辣", pinyin: "là", meaning: "hot" },
  268: { character: "来不及", pinyin: "láibují", meaning: "too late" },
  269: { character: "来得及", pinyin: "láidéjí", meaning: "in time" },
  270: { character: "来自", pinyin: "láizì", meaning: "from" },
  271: { character: "懒", pinyin: "lǎn", meaning: "lazy" },
  272: { character: "浪费", pinyin: "làngfèi", meaning: "waste" },
  273: { character: "浪漫", pinyin: "làngmàn", meaning: "romantic" },
  274: { character: "老虎", pinyin: "lǎohǔ", meaning: "tiger" },
  275: { character: "冷静", pinyin: "lěngjìng", meaning: "calm" },
  276: { character: "礼拜天", pinyin: "lǐbài tiān", meaning: "Sunday" },
  277: { character: "礼貌", pinyin: "lǐmào", meaning: "polite" },
  278: { character: "理发", pinyin: "lǐfǎ", meaning: "haircut" },
  279: { character: "理解", pinyin: "lǐjiě", meaning: "understand" },
  280: { character: "理想", pinyin: "lǐxiǎng", meaning: "ideal" },
  281: { character: "力气", pinyin: "lì qì", meaning: "strength" },
  282: { character: "厉害", pinyin: "lìhài", meaning: "sharp" },
  283: { character: "例如", pinyin: "lìrú", meaning: "E.g" },
  284: { character: "俩", pinyin: "liǎ", meaning: "both" },
  285: { character: "连", pinyin: "lián", meaning: "even" },
  286: { character: "联系", pinyin: "liánxì", meaning: "connect" },
  287: { character: "凉快", pinyin: "liángkuai", meaning: "cool" },
  288: { character: "零钱", pinyin: "líng qián", meaning: "small change" },
  289: { character: "另外", pinyin: "lìngwài", meaning: "in addition" },
  290: { character: "留", pinyin: "liú", meaning: "Keep" },
  291: { character: "流利", pinyin: "liúlì", meaning: "fluent" },
  292: { character: "流行", pinyin: "liúxíng", meaning: "Popularity" },
  293: { character: "旅行", pinyin: "lǚxíng", meaning: "travel" },
  294: { character: "律师", pinyin: "lǜshī", meaning: "lawyer" },
  295: { character: "乱", pinyin: "luàn", meaning: "chaos" },
  296: { character: "麻烦", pinyin: "máfan", meaning: "trouble" },
  297: { character: "马虎", pinyin: "mǎhǔ", meaning: "careless" },
  298: { character: "满", pinyin: "mǎn", meaning: "Full" },
  299: { character: "毛", pinyin: "máo", meaning: "hair" },
  300: { character: "毛巾", pinyin: "máojīn", meaning: "towel" },
  301: { character: "美丽", pinyin: "měilì", meaning: "beauty" },
  302: { character: "梦", pinyin: "Mèng", meaning: "Dream" },
  303: { character: "迷路", pinyin: "mílù", meaning: "get lost" },
  304: { character: "密码", pinyin: "mìmǎ", meaning: "password" },
  305: { character: "免费", pinyin: "miǎnfèi", meaning: "free" },
  306: { character: "秒", pinyin: "miǎo", meaning: "second" },
  307: { character: "民族", pinyin: "mínzú", meaning: "nationality" },
  308: { character: "母亲", pinyin: "mǔqīn", meaning: "Mother" },
  309: { character: "目的", pinyin: "mùdì", meaning: "Purpose" },
  310: { character: "耐心", pinyin: "nàixīn", meaning: "patience" },
  311: { character: "难道", pinyin: "nándào", meaning: "Is it" },
  312: { character: "难受", pinyin: "nánshòu", meaning: "uncomfortable" },
  313: { character: "内", pinyin: "nèi", meaning: "Inside" },
  314: { character: "内容", pinyin: "nèiróng", meaning: "content" },
  315: { character: "能力", pinyin: "nénglì", meaning: "ability" },
  316: { character: "年龄", pinyin: "niánlíng", meaning: "age" },
  317: { character: "弄", pinyin: "nòng", meaning: "alley" },
  318: { character: "暖和", pinyin: "nuǎnhuo", meaning: "warm" },
  319: { character: "偶尔", pinyin: "ǒu'ěr", meaning: "Occasionally" },
  320: { character: "排队", pinyin: "páiduì", meaning: "queue" },
  321: { character: "排列", pinyin: "páiliè", meaning: "arrangement" },
  322: { character: "判断", pinyin: "pànduàn", meaning: "judge" },
  323: { character: "陪", pinyin: "péi", meaning: "accompany" },
  324: { character: "批评", pinyin: "pīpíng", meaning: "criticize" },
  325: { character: "皮肤", pinyin: "pífū", meaning: "skin" },
  326: { character: "脾气", pinyin: "píqì", meaning: "temper" },
  327: { character: "篇", pinyin: "piān", meaning: "articles" },
  328: { character: "骗", pinyin: "piàn", meaning: "cheat" },
  329: { character: "乒乓球", pinyin: "pīngpāng qiú", meaning: "pingpong" },
  330: { character: "平时", pinyin: "píngshí", meaning: "usually" },
  331: { character: "破", pinyin: "pò", meaning: "break" },
  332: { character: "葡萄", pinyin: "pútáo", meaning: "Grape" },
  333: { character: "普遍", pinyin: "pǔbiàn", meaning: "universal" },
  334: { character: "普通话", pinyin: "pǔtōnghuà", meaning: "mandarin" },
  335: { character: "其次", pinyin: "qícì", meaning: "Second" },
  336: { character: "其中", pinyin: "qízhōng", meaning: "in" },
  337: { character: "气候", pinyin: "qìhòu", meaning: "climate" },
  338: { character: "千万", pinyin: "qiān wàn", meaning: "ten million" },
  339: { character: "签证", pinyin: "qiānzhèng", meaning: "visa" },
  340: { character: "敲", pinyin: "qiāo", meaning: "knock" },
  341: { character: "桥", pinyin: "qiáo", meaning: "bridge" },
  342: { character: "巧克力", pinyin: "qiǎokèlì", meaning: "chocolate" },
  343: { character: "亲戚", pinyin: "qīnqī", meaning: "relative" },
  344: { character: "轻", pinyin: "qīng", meaning: "light" },
  345: { character: "轻松", pinyin: "qīngsōng", meaning: "easy" },
  346: { character: "情况", pinyin: "qíngkuàng", meaning: "Happening" },
  347: { character: "穷", pinyin: "qióng", meaning: "poverty" },
  348: { character: "区别", pinyin: "qūbié", meaning: "the difference" },
  349: { character: "取", pinyin: "qǔ", meaning: "Pick" },
  350: { character: "全部", pinyin: "quánbù", meaning: "all" },
  351: { character: "缺点", pinyin: "quēdiǎn", meaning: "shortcoming" },
  352: { character: "缺少", pinyin: "quēshǎo", meaning: "Lack" },
  353: { character: "却", pinyin: "què", meaning: "but" },
  354: { character: "确实", pinyin: "quèshí", meaning: "really" },
  355: { character: "然而", pinyin: "rán'ér", meaning: "However" },
  356: { character: "热闹", pinyin: "rènào", meaning: "lively" },
  357: { character: "任何", pinyin: "rènhé", meaning: "any" },
  358: { character: "任务", pinyin: "rènwù", meaning: "Task" },
  359: { character: "扔", pinyin: "rēng", meaning: "Throw" },
  360: { character: "仍然", pinyin: "réngrán", meaning: "still" },
  361: { character: "日记", pinyin: "rìjì", meaning: "journal" },
  362: { character: "入口", pinyin: "rùkǒu", meaning: "Entrance" },
  363: { character: "散步", pinyin: "sànbù", meaning: "walk" },
  364: { character: "森林", pinyin: "sēnlín", meaning: "forest" },
  365: { character: "沙发", pinyin: "shāfā", meaning: "sofa" },
  366: { character: "伤心", pinyin: "shāngxīn", meaning: "sad" },
  367: { character: "商量", pinyin: "shāngliáng", meaning: "discuss" },
  368: { character: "稍微", pinyin: "shāowéi", meaning: "a little" },
  369: { character: "勺子", pinyin: "sháozi", meaning: "spoon" },
  370: { character: "社会", pinyin: "shèhuì", meaning: "society" },
  371: { character: "申请", pinyin: "shēnqǐng", meaning: "Application" },
  372: { character: "深", pinyin: "shēn", meaning: "deep" },
  373: { character: "甚至", pinyin: "shènzhì", meaning: "even" },
  374: { character: "生活", pinyin: "shēnghuó", meaning: "Life" },
  375: { character: "生命", pinyin: "shēngmìng", meaning: "life" },
  376: { character: "生意", pinyin: "shēngyì", meaning: "Business" },
  377: { character: "省", pinyin: "shěng", meaning: "Province" },
  378: { character: "剩", pinyin: "shèng", meaning: "leftover" },
  379: { character: "失败", pinyin: "shībài", meaning: "fail" },
  380: { character: "失望", pinyin: "shīwàng", meaning: "disappointment" },
  381: { character: "师傅", pinyin: "shīfù", meaning: "master" },
  382: { character: "十分", pinyin: "shí fēn", meaning: "very" },
  383: { character: "实际", pinyin: "shíjì", meaning: "actual" },
  384: { character: "实在", pinyin: "shízài", meaning: "really" },
  385: { character: "使", pinyin: "shǐ", meaning: "Make" },
  386: { character: "使用", pinyin: "shǐyòng", meaning: "use" },
  387: { character: "世纪", pinyin: "shìjì", meaning: "century" },
  388: { character: "是否", pinyin: "shìfǒu", meaning: "whether" },
  389: { character: "适合", pinyin: "shìhé", meaning: "Suitable for" },
  390: { character: "适应", pinyin: "shìyìng", meaning: "adapt" },
  391: { character: "收", pinyin: "shōu", meaning: "receive" },
  392: { character: "收入", pinyin: "shōurù", meaning: "income" },
  393: { character: "收拾", pinyin: "shōushí", meaning: "tidy" },
  394: { character: "首都", pinyin: "shǒudū", meaning: "capital" },
  395: { character: "首先", pinyin: "shǒuxiān", meaning: "first" },
  396: {
    character: "受不了",
    pinyin: "shòu bùliǎo",
    meaning: "can't stand it",
  },
  397: { character: "受到", pinyin: "shòudào", meaning: "received" },
  398: { character: "售货员", pinyin: "shòuhuòyuán", meaning: "salesperson" },
  399: { character: "输", pinyin: "shū", meaning: "lose" },
  400: { character: "熟悉", pinyin: "shúxī", meaning: "familiar" },
  401: { character: "数量", pinyin: "shùliàng", meaning: "quantity" },
  402: { character: "数字", pinyin: "shùzì", meaning: "number" },
  403: { character: "帅", pinyin: "shuài", meaning: "handsome" },
  404: { character: "顺便", pinyin: "shùnbiàn", meaning: "by the way" },
  405: { character: "顺利", pinyin: "shùnlì", meaning: "smoothly" },
  406: { character: "顺序", pinyin: "shùnxù", meaning: "order" },
  407: { character: "说明", pinyin: "shuōmíng", meaning: "illustrate" },
  408: { character: "硕士", pinyin: "shuòshì", meaning: "master" },
  409: { character: "死", pinyin: "sǐ", meaning: "die" },
  410: { character: "速度", pinyin: "sùdù", meaning: "speed" },
  411: { character: "塑料袋", pinyin: "sùliào dài", meaning: "plastic bag" },
  412: { character: "酸", pinyin: "suān", meaning: "acid" },
  413: { character: "随便", pinyin: "suíbiàn", meaning: "casual" },
  414: { character: "随着", pinyin: "suízhe", meaning: "along with" },
  415: { character: "孙子", pinyin: "sūnzi", meaning: "grandson" },
  416: { character: "所有", pinyin: "suǒyǒu", meaning: "all" },
  417: { character: "台", pinyin: "tái", meaning: "tower" },
  418: { character: "抬", pinyin: "tái", meaning: "lift" },
  419: { character: "态度", pinyin: "tàidù", meaning: "manner" },
  420: { character: "谈", pinyin: "tán", meaning: "talk" },
  421: { character: "弹钢琴", pinyin: "dàn gāngqín", meaning: "play piano" },
  422: { character: "汤", pinyin: "tāng", meaning: "soup" },
  423: { character: "糖", pinyin: "táng", meaning: "sugar" },
  424: { character: "躺", pinyin: "tǎng", meaning: "lie" },
  425: { character: "趟", pinyin: "tàng", meaning: "trip" },
  426: { character: "讨论", pinyin: "tǎolùn", meaning: "discuss" },
  427: { character: "讨厌", pinyin: "tǎoyàn", meaning: "Hate" },
  428: { character: "特点", pinyin: "tèdiǎn", meaning: "Features" },
  429: { character: "提", pinyin: "tí", meaning: "carry" },
  430: { character: "提供", pinyin: "tígōng", meaning: "supply" },
  431: { character: "提前", pinyin: "tíqián", meaning: "in advance" },
  432: { character: "提醒", pinyin: "tíxǐng", meaning: "remind" },
  433: { character: "填空", pinyin: "tiánkòng", meaning: "fill in the blank" },
  434: { character: "条件", pinyin: "tiáojiàn", meaning: "condition" },
  435: { character: "停", pinyin: "tíng", meaning: "stop" },
  436: { character: "挺", pinyin: "tǐng", meaning: "quite" },
  437: { character: "通过", pinyin: "tōngguò", meaning: "pass" },
  438: { character: "通知", pinyin: "tōngzhī", meaning: "Notice" },
  439: { character: "同情", pinyin: "tóngqíng", meaning: "pity" },
  440: { character: "同时", pinyin: "tóngshí", meaning: "at the same time" },
  441: { character: "推", pinyin: "tuī", meaning: "push" },
  442: { character: "推迟", pinyin: "tuīchí", meaning: "put off" },
  443: { character: "脱", pinyin: "tuō", meaning: "take off" },
  444: { character: "袜子", pinyin: "wàzi", meaning: "sock" },
  445: { character: "完全", pinyin: "wánquán", meaning: "completely" },
  446: { character: "网球", pinyin: "wǎngqiú", meaning: "tennis" },
  447: { character: "网站", pinyin: "wǎngzhàn", meaning: "website" },
  448: { character: "往往", pinyin: "wǎngwǎng", meaning: "often" },
  449: { character: "危险", pinyin: "wéixiǎn", meaning: "Danger" },
  450: { character: "卫生间", pinyin: "wèishēngjiān", meaning: "bathroom" },
  451: { character: "味道", pinyin: "wèidào", meaning: "smell" },
  452: { character: "温度", pinyin: "wēndù", meaning: "temperature" },
  453: { character: "文章", pinyin: "wénzhāng", meaning: "article" },
  454: { character: "污染", pinyin: "wūrǎn", meaning: "Pollution" },
  455: { character: "无", pinyin: "wú", meaning: "none" },
  456: { character: "无聊", pinyin: "wúliáo", meaning: "boring" },
  457: { character: "无论", pinyin: "wúlùn", meaning: "regardless" },
  458: { character: "误会", pinyin: "wùhuì", meaning: "Misunderstand" },
  459: { character: "西红柿", pinyin: "xīhóngshì", meaning: "tomato" },
  460: { character: "吸引", pinyin: "xīyǐn", meaning: "absorb" },
  461: { character: "咸", pinyin: "xián", meaning: "salty" },
  462: { character: "现金", pinyin: "xiànjīn", meaning: "cash" },
  463: { character: "羡慕", pinyin: "xiànmù", meaning: "envious" },
  464: { character: "相反", pinyin: "xiāngfǎn", meaning: "on the contrary" },
  465: { character: "相同", pinyin: "xiāngtóng", meaning: "same" },
  466: { character: "香", pinyin: "xiāng", meaning: "fragrant" },
  467: { character: "详细", pinyin: "xiángxì", meaning: "detailed" },
  468: { character: "响", pinyin: "xiǎng", meaning: "ring" },
  469: { character: "橡皮", pinyin: "xiàngpí", meaning: "Rubber" },
  470: { character: "消息", pinyin: "xiāoxī", meaning: "information" },
  471: { character: "小吃", pinyin: "xiǎochī", meaning: "snack" },
  472: { character: "小伙子", pinyin: "xiǎohuǒzi", meaning: "lad" },
  473: { character: "小说", pinyin: "xiǎoshuō", meaning: "novel" },
  474: { character: "笑话", pinyin: "xiàohuà", meaning: "joke" },
  475: { character: "效果", pinyin: "xiàoguǒ", meaning: "Effect" },
  476: { character: "心情", pinyin: "xīnqíng", meaning: "Feeling" },
  477: { character: "辛苦", pinyin: "xīnkǔ", meaning: "Hard" },
  478: { character: "信封", pinyin: "xìnfēng", meaning: "envelope" },
  479: { character: "信息", pinyin: "xìnxī", meaning: "information" },
  480: { character: "信心", pinyin: "xìnxīn", meaning: "confidence" },
  481: { character: "兴奋", pinyin: "xīngfèn", meaning: "excited" },
  482: { character: "行", pinyin: "xíng", meaning: "Row" },
  483: { character: "醒", pinyin: "xǐng", meaning: "Awake" },
  484: { character: "幸福", pinyin: "xìngfú", meaning: "happiness" },
  485: { character: "性别", pinyin: "xìngbié", meaning: "gender" },
  486: { character: "性格", pinyin: "xìnggé", meaning: "character" },
  487: { character: "修理", pinyin: "xiūlǐ", meaning: "repair" },
  488: { character: "许多", pinyin: "xǔduō", meaning: "many" },
  489: { character: "学期", pinyin: "xuéqí", meaning: "semester" },
  490: { character: "压力", pinyin: "yālì", meaning: "pressure" },
  491: { character: "呀", pinyin: "ya", meaning: "ah" },
  492: { character: "牙膏", pinyin: "yágāo", meaning: "toothpaste" },
  493: { character: "亚洲", pinyin: "yàzhōu", meaning: "Asia" },
  494: { character: "严格", pinyin: "yángé", meaning: "strict" },
  495: { character: "严重", pinyin: "yánzhòng", meaning: "serious" },
  496: { character: "研究", pinyin: "yánjiū", meaning: "Research" },
  497: { character: "盐", pinyin: "yán", meaning: "Salt" },
  498: { character: "眼镜", pinyin: "yǎnjìng", meaning: "Glasses" },
  499: { character: "演出", pinyin: "yǎnchū", meaning: "show" },
  500: { character: "演员", pinyin: "yǎnyuán", meaning: "actor" },
  501: { character: "阳光", pinyin: "yángguāng", meaning: "Sunlight" },
  502: { character: "养成", pinyin: "yǎng chéng", meaning: "cultivate" },
  503: { character: "样子", pinyin: "yàngzi", meaning: "look" },
  504: { character: "邀请", pinyin: "yāoqǐng", meaning: "invite" },
  505: { character: "要是", pinyin: "yàoshi", meaning: "if" },
  506: { character: "钥匙", pinyin: "yàoshi", meaning: "key" },
  507: { character: "也许", pinyin: "yěxǔ", meaning: "Maybe" },
  508: { character: "叶子", pinyin: "yèzi", meaning: "leaf" },
  509: { character: "页", pinyin: "yè", meaning: "Page" },
  510: { character: "一切", pinyin: "yīqiè", meaning: "everything" },
  511: { character: "以", pinyin: "yǐ", meaning: "by" },
  512: { character: "以为", pinyin: "yǐwéi", meaning: "Think" },
  513: { character: "艺术", pinyin: "yìshù", meaning: "Art" },
  514: { character: "意见", pinyin: "yìjiàn", meaning: "Opinion" },
  515: { character: "因此", pinyin: "yīncǐ", meaning: "therefore" },
  516: { character: "引起", pinyin: "yǐnqǐ", meaning: "cause" },
  517: { character: "印象", pinyin: "yìnxiàng", meaning: "impression" },
  518: { character: "赢", pinyin: "yíng", meaning: "win" },
  519: { character: "应聘", pinyin: "yìngpìn", meaning: "apply" },
  520: { character: "永远", pinyin: "yǒngyuǎn", meaning: "forever" },
  521: { character: "勇敢", pinyin: "yǒnggǎn", meaning: "brave" },
  522: { character: "优点", pinyin: "yōudiǎn", meaning: "advantage" },
  523: { character: "优秀", pinyin: "yōuxiù", meaning: "excellent" },
  524: { character: "幽默", pinyin: "yōumò", meaning: "humor" },
  525: { character: "尤其", pinyin: "yóuqí", meaning: "especially" },
  526: { character: "由", pinyin: "yóu", meaning: "Depend on" },
  527: { character: "由于", pinyin: "yóuyú", meaning: "because" },
  528: { character: "邮局", pinyin: "yóujú", meaning: "post office" },
  529: { character: "友好", pinyin: "yǒuhǎo", meaning: "friendly" },
  530: { character: "友谊", pinyin: "yǒuyì", meaning: "friendship" },
  531: { character: "有趣", pinyin: "yǒuqù", meaning: "interesting" },
  532: { character: "于是", pinyin: "yúshì", meaning: "then" },
  533: { character: "愉快", pinyin: "yúkuài", meaning: "joy" },
  534: { character: "与", pinyin: "yǔ", meaning: "and" },
  535: { character: "羽毛球", pinyin: "yǔmáoqiú", meaning: "badminton" },
  536: { character: "语法", pinyin: "yǔfǎ", meaning: "grammar" },
  537: { character: "语言", pinyin: "yǔyán", meaning: "language" },
  538: { character: "预习", pinyin: "yùxí", meaning: "preview" },
  539: { character: "原来", pinyin: "yuánlái", meaning: "turn out to be" },
  540: { character: "原谅", pinyin: "yuánliàng", meaning: "forgive" },
  541: { character: "原因", pinyin: "yuányīn", meaning: "reason" },
  542: { character: "约会", pinyin: "yuēhuì", meaning: "Dating" },
  543: { character: "阅读", pinyin: "yuèdú", meaning: "read" },
  544: { character: "云", pinyin: "yún", meaning: "cloud" },
  545: { character: "允许", pinyin: "yǔnxǔ", meaning: "allow" },
  546: { character: "杂志", pinyin: "zázhì", meaning: "Magazine" },
  547: { character: "咱们", pinyin: "zánmen", meaning: "we" },
  548: { character: "暂时", pinyin: "zhànshí", meaning: "temporary" },
  549: { character: "脏", pinyin: "zàng", meaning: "dirty" },
  550: { character: "责任", pinyin: "zérèn", meaning: "responsibility" },
  551: { character: "增加", pinyin: "zēngjiā", meaning: "Increase" },
  552: { character: "占线", pinyin: "zhànxiàn", meaning: "busy" },
  553: { character: "招聘", pinyin: "zhāopìn", meaning: "Recruitment" },
  554: { character: "照", pinyin: "zhào", meaning: "According to" },
  555: { character: "真正", pinyin: "zhēnzhèng", meaning: "real" },
  556: { character: "整理", pinyin: "zhěnglǐ", meaning: "tidy" },
  557: { character: "正常", pinyin: "zhèngcháng", meaning: "normal" },
  558: { character: "正好", pinyin: "zhènghǎo", meaning: "just right" },
  559: { character: "正确", pinyin: "zhèngquè", meaning: "correct" },
  560: { character: "正式", pinyin: "zhèngshì", meaning: "formal" },
  561: { character: "证明", pinyin: "zhèngmíng", meaning: "prove" },
  562: { character: "之", pinyin: "zhī", meaning: "Of" },
  563: { character: "支持", pinyin: "zhīchí", meaning: "support" },
  564: { character: "知识", pinyin: "zhīshì", meaning: "Knowledge" },
  565: { character: "直接", pinyin: "zhíjiē", meaning: "direct" },
  566: { character: "值得", pinyin: "zhídé", meaning: "worth" },
  567: { character: "职业", pinyin: "zhíyè", meaning: "Profession" },
  568: { character: "植物", pinyin: "zhíwù", meaning: "plant" },
  569: { character: "只好", pinyin: "zhǐhǎo", meaning: "had to" },
  570: { character: "只要", pinyin: "zhǐyào", meaning: "if only" },
  571: { character: "指", pinyin: "zhǐ", meaning: "refer to" },
  572: { character: "至少", pinyin: "zhìshǎo", meaning: "At least" },
  573: { character: "质量", pinyin: "zhìliàng", meaning: "quality" },
  574: { character: "重", pinyin: "zhòng", meaning: "Heavy" },
  575: { character: "重点", pinyin: "zhòngdiǎn", meaning: "focus" },
  576: { character: "重视", pinyin: "zhòngshì", meaning: "Pay attention to" },
  577: { character: "周围", pinyin: "zhōuwéi", meaning: "around" },
  578: { character: "主意", pinyin: "zhǔyì", meaning: "idea" },
  579: { character: "祝贺", pinyin: "zhùhè", meaning: "congratulate" },
  580: { character: "著名", pinyin: "zhùmíng", meaning: "Famous" },
  581: { character: "专门", pinyin: "zhuānmén", meaning: "specialized" },
  582: { character: "专业", pinyin: "zhuānyè", meaning: "specialized" },
  583: { character: "转", pinyin: "zhuǎn", meaning: "change" },
  584: { character: "赚", pinyin: "zhuàn", meaning: "earn" },
  585: { character: "准确", pinyin: "zhǔnquè", meaning: "precise" },
  586: { character: "准时", pinyin: "zhǔnshí", meaning: "on time" },
  587: { character: "仔细", pinyin: "zǐxì", meaning: "carefulness" },
  588: { character: "自然", pinyin: "zìrán", meaning: "nature" },
  589: { character: "自信", pinyin: "zìxìn", meaning: "confidence" },
  590: { character: "总结", pinyin: "zǒngjié", meaning: "Summarize" },
  591: { character: "租", pinyin: "zū", meaning: "rent" },
  592: { character: "最好", pinyin: "zuì hǎo", meaning: "most" },
  593: { character: "尊重", pinyin: "zūnzhòng", meaning: "respect" },
  594: { character: "左右", pinyin: "zuǒyòu", meaning: "about" },
  595: { character: "作家", pinyin: "zuòjiā", meaning: "writer" },
  596: { character: "作用", pinyin: "zuòyòng", meaning: "effect" },
  597: { character: "作者", pinyin: "zuòzhě", meaning: "author" },
  598: { character: "座", pinyin: "zuò", meaning: "seat" },
  599: { character: "座位", pinyin: "zuòwèi", meaning: "seat" },
};

const hskAllDict = {
  0: { character: "阿姨", pinyin: "Āyí", meaning: "Aunt" },
  1: { character: "啊", pinyin: "a", meaning: "what" },
  2: { character: "矮", pinyin: "ǎi", meaning: "short" },
  3: { character: "爱", pinyin: "ài", meaning: "Love" },
  4: { character: "爱好", pinyin: "àihào", meaning: "Hobby" },
  5: { character: "爱情", pinyin: "àiqíng", meaning: "love" },
  6: { character: "安静", pinyin: "ānjìng", meaning: "Quiet" },
  7: { character: "安排", pinyin: "ānpái", meaning: "arrange" },
  8: { character: "安全", pinyin: "ānquán", meaning: "Safety" },
  9: { character: "按时", pinyin: "ànshí", meaning: "on time" },
  10: { character: "按照", pinyin: "ànzhào", meaning: "according to" },
  11: { character: "八", pinyin: "bā", meaning: "Eight" },
  12: { character: "把", pinyin: "bǎ", meaning: "Bundle" },
  13: { character: "爸爸", pinyin: "bàba", meaning: "dad" },
  14: { character: "吧", pinyin: "ba", meaning: "Bar" },
  15: { character: "白", pinyin: "bái", meaning: "White" },
  16: { character: "百", pinyin: "bǎi", meaning: "Hundred" },
  17: { character: "百分之", pinyin: "bǎi fēn zhī", meaning: "percent" },
  18: { character: "班", pinyin: "bān", meaning: "class" },
  19: { character: "搬", pinyin: "bān", meaning: "move" },
  20: { character: "办法", pinyin: "bànfǎ", meaning: "Method" },
  21: { character: "办公室", pinyin: "bàngōngshì", meaning: "office" },
  22: { character: "半", pinyin: "bàn", meaning: "Half" },
  23: { character: "帮忙", pinyin: "bāngmáng", meaning: "help" },
  24: { character: "帮助", pinyin: "bāngzhù", meaning: "help" },
  25: { character: "棒", pinyin: "bàng", meaning: "Great" },
  26: { character: "包", pinyin: "bāo", meaning: "Bag" },
  27: { character: "包子", pinyin: "bāozi", meaning: "steamed stuffed bun" },
  28: { character: "饱", pinyin: "bǎo", meaning: "full" },
  29: { character: "保护", pinyin: "bǎohù", meaning: "Protect" },
  30: { character: "保证", pinyin: "bǎozhèng", meaning: "ensure" },
  31: { character: "报名", pinyin: "bàomíng", meaning: "Sign up" },
  32: { character: "报纸", pinyin: "bàozhǐ", meaning: "newspaper" },
  33: { character: "抱", pinyin: "bào", meaning: "hug" },
  34: { character: "抱歉", pinyin: "bàoqiàn", meaning: "Feel sorry" },
  35: { character: "杯子", pinyin: "bēizi", meaning: "cup" },
  36: { character: "北方", pinyin: "běifāng", meaning: "north" },
  37: { character: "北京", pinyin: "běijīng", meaning: "Beijing" },
  38: { character: "倍", pinyin: "bèi", meaning: "times" },
  39: { character: "被", pinyin: "bèi", meaning: "quilt" },
  40: { character: "本", pinyin: "běn", meaning: "Book" },
  41: { character: "本来", pinyin: "běnlái", meaning: "originally" },
  42: { character: "笨", pinyin: "bèn", meaning: "stupid" },
  43: { character: "鼻子", pinyin: "bízi", meaning: "nose" },
  44: { character: "比", pinyin: "bǐ", meaning: "Compare" },
  45: { character: "比较", pinyin: "bǐjiào", meaning: "Compare" },
  46: { character: "比如", pinyin: "bǐrú", meaning: "for example" },
  47: { character: "比赛", pinyin: "bǐsài", meaning: "Contest" },
  48: { character: "笔记本", pinyin: "bǐjìběn", meaning: "notebook" },
  49: { character: "必须", pinyin: "bìxū", meaning: "must" },
  50: { character: "毕业", pinyin: "bìyè", meaning: "graduate" },
  51: { character: "变化", pinyin: "biànhuà", meaning: "Variety" },
  52: { character: "遍", pinyin: "biàn", meaning: "all over" },
  53: { character: "标准", pinyin: "biāozhǔn", meaning: "standard" },
  54: { character: "表格", pinyin: "biǎogé", meaning: "sheet" },
  55: { character: "表示", pinyin: "biǎoshì", meaning: "express" },
  56: { character: "表演", pinyin: "biǎoyǎn", meaning: "Performance" },
  57: { character: "表扬", pinyin: "biǎoyáng", meaning: "praise" },
  58: { character: "别", pinyin: "bié", meaning: "do not" },
  59: { character: "别人", pinyin: "biérén", meaning: "other people" },
  60: { character: "宾馆", pinyin: "bīnguǎn", meaning: "hotel" },
  61: { character: "冰箱", pinyin: "bīngxiāng", meaning: "refrigerator" },
  62: { character: "饼干", pinyin: "bǐnggān", meaning: "biscuit" },
  63: { character: "并且", pinyin: "bìngqiě", meaning: "and" },
  64: { character: "博士", pinyin: "bóshì", meaning: "PhD" },
  65: { character: "不", pinyin: "bù", meaning: "Do not" },
  66: {
    character: "不但…而且…",
    pinyin: "bùdàn…érqiě…",
    meaning: "not only but also…",
  },
  67: { character: "不得不", pinyin: "bùdé bù", meaning: "have to" },
  68: { character: "不管", pinyin: "bùguǎn", meaning: "in spite of" },
  69: { character: "不过", pinyin: "bùguò", meaning: "but" },
  70: { character: "不仅", pinyin: "bùjǐn", meaning: "not only" },
  71: { character: "不客气", pinyin: "bù kèqì", meaning: "You're welcome" },
  72: { character: "部分", pinyin: "bùfèn", meaning: "part" },
  73: { character: "擦", pinyin: "cā", meaning: "wipe" },
  74: { character: "猜", pinyin: "cāi", meaning: "Guess" },
  75: { character: "材料", pinyin: "cáiliào", meaning: "Material" },
  76: { character: "菜", pinyin: "cài", meaning: "vegetable" },
  77: { character: "菜单", pinyin: "càidān", meaning: "menu" },
  78: { character: "参观", pinyin: "cānguān", meaning: "visit" },
  79: { character: "参加", pinyin: "cānjiā", meaning: "join" },
  80: { character: "餐厅", pinyin: "cāntīng", meaning: "Dining room" },
  81: { character: "草", pinyin: "cǎo", meaning: "Grass" },
  82: { character: "厕所", pinyin: "cèsuǒ", meaning: "bathroom" },
  83: { character: "层", pinyin: "céng", meaning: "Floor" },
  84: { character: "茶", pinyin: "chá", meaning: "Tea" },
  85: { character: "差", pinyin: "chà", meaning: "Difference" },
  86: { character: "差不多", pinyin: "chàbùduō", meaning: "almost" },
  87: {
    character: "长（形容词）",
    pinyin: "zhǎng (xíngróngcí)",
    meaning: "long (adjective)",
  },
  88: { character: "长城", pinyin: "chángchéng", meaning: "Great Wall" },
  89: { character: "长江", pinyin: "chángjiāng", meaning: "Yangtze" },
  90: { character: "尝", pinyin: "cháng", meaning: "taste" },
  91: { character: "场", pinyin: "chǎng", meaning: "field" },
  92: { character: "唱歌", pinyin: "chànggē", meaning: "Sing" },
  93: { character: "超过", pinyin: "chāoguò", meaning: "Exceed" },
  94: { character: "超市", pinyin: "chāoshì", meaning: "supermarket" },
  95: { character: "衬衫", pinyin: "chènshān", meaning: "shirt" },
  96: { character: "成功", pinyin: "chénggōng", meaning: "success" },
  97: { character: "成绩", pinyin: "chéngjī", meaning: "score" },
  98: { character: "成为", pinyin: "chéngwéi", meaning: "become" },
  99: { character: "诚实", pinyin: "chéngshí", meaning: "honest" },
  100: { character: "城市", pinyin: "chéngshì", meaning: "City" },
  101: { character: "乘坐", pinyin: "chéngzuò", meaning: "ride" },
  102: { character: "吃", pinyin: "chī", meaning: "eat" },
  103: { character: "吃惊", pinyin: "chījīng", meaning: "be surprised" },
  104: { character: "迟到", pinyin: "chídào", meaning: "be late" },
  105: { character: "重新", pinyin: "chóngxīn", meaning: "again" },
  106: { character: "抽烟", pinyin: "chōuyān", meaning: "smokes" },
  107: { character: "出", pinyin: "chū", meaning: "out" },
  108: { character: "出差", pinyin: "chūchāi", meaning: "On business trip" },
  109: { character: "出发", pinyin: "chūfā", meaning: "Set off" },
  110: { character: "出生", pinyin: "chūshēng", meaning: "born" },
  111: { character: "出现", pinyin: "chūxiàn", meaning: "Appear" },
  112: { character: "出租车", pinyin: "chūzū chē", meaning: "taxi" },
  113: { character: "除了", pinyin: "chúle", meaning: "Apart from" },
  114: { character: "厨房", pinyin: "chúfáng", meaning: "kitchen" },
  115: { character: "穿", pinyin: "chuān", meaning: "Put on" },
  116: { character: "传真", pinyin: "chuánzhēn", meaning: "fax" },
  117: { character: "船", pinyin: "chuán", meaning: "ferry" },
  118: { character: "窗户", pinyin: "chuānghù", meaning: "window" },
  119: { character: "春", pinyin: "chūn", meaning: "spring" },
  120: { character: "词典", pinyin: "cídiǎn", meaning: "dictionary" },
  121: { character: "词语", pinyin: "cíyǔ", meaning: "words" },
  122: { character: "次", pinyin: "cì", meaning: "Second-rate" },
  123: { character: "聪明", pinyin: "cōngmíng", meaning: "clever" },
  124: { character: "从", pinyin: "cóng", meaning: "from" },
  125: { character: "从来", pinyin: "cónglái", meaning: "never" },
  126: { character: "粗心", pinyin: "cūxīn", meaning: "careless" },
  127: { character: "存", pinyin: "cún", meaning: "live" },
  128: { character: "错", pinyin: "cuò", meaning: "wrong" },
  129: { character: "错误", pinyin: "cuòwù", meaning: "mistake" },
  130: { character: "答案", pinyin: "dá'àn", meaning: "Answer" },
  131: { character: "打扮", pinyin: "dǎbàn", meaning: "dress up" },
  132: { character: "打电话", pinyin: "dǎ diànhuà", meaning: "Call up" },
  133: { character: "打篮球", pinyin: "dǎ lánqiú", meaning: "play basketball" },
  134: { character: "打扰", pinyin: "dǎrǎo", meaning: "disturb" },
  135: { character: "打扫", pinyin: "dǎsǎo", meaning: "clean" },
  136: { character: "打算", pinyin: "dǎsuàn", meaning: "Intend, plan" },
  137: { character: "打印", pinyin: "dǎyìn", meaning: "Print" },
  138: { character: "打招呼", pinyin: "dǎzhāohū", meaning: "say hello" },
  139: { character: "打折", pinyin: "dǎzhé", meaning: "Discount" },
  140: { character: "打针", pinyin: "dǎzhēn", meaning: "inject" },
  141: { character: "大", pinyin: "dà", meaning: "Big" },
  142: { character: "大概", pinyin: "dàgài", meaning: "perhaps" },
  143: { character: "大家", pinyin: "dàjiā", meaning: "Everyone" },
  144: { character: "大使馆", pinyin: "dàshǐ guǎn", meaning: "embassy" },
  145: { character: "大约", pinyin: "dàyuē", meaning: "About" },
  146: { character: "大夫", pinyin: "dàfū", meaning: "doctor" },
  147: { character: "带", pinyin: "dài", meaning: "bring" },
  148: { character: "戴", pinyin: "dài", meaning: "wore" },
  149: { character: "担心", pinyin: "dānxīn", meaning: "Worry" },
  150: { character: "蛋糕", pinyin: "Dàngāo", meaning: "cake" },
  151: { character: "当", pinyin: "dāng", meaning: "when" },
  152: { character: "当然", pinyin: "dāngrán", meaning: "certainly" },
  153: { character: "当时", pinyin: "dāngshí", meaning: "then" },
  154: { character: "刀", pinyin: "dāo", meaning: "Knife" },
  155: { character: "导游", pinyin: "dǎoyóu", meaning: "Tourist guide" },
  156: { character: "到", pinyin: "dào", meaning: "arrive" },
  157: { character: "到处", pinyin: "dàochù", meaning: "everywhere" },
  158: { character: "到底", pinyin: "dàodǐ", meaning: "in the end" },
  159: { character: "倒", pinyin: "dào", meaning: "fall" },
  160: { character: "道歉", pinyin: "dàoqiàn", meaning: "Apologize" },
  161: { character: "得意", pinyin: "déyì", meaning: "proud" },
  162: {
    character: "地（助词）",
    pinyin: "de (zhùcí)",
    meaning: "ground (particle)",
  },
  163: { character: "的", pinyin: "de", meaning: "of" },
  164: {
    character: "得（助词）",
    pinyin: "dé (zhùcí)",
    meaning: "get (particle)",
  },
  165: {
    character: "得（助动词）",
    pinyin: "dé (zhùdòngcí)",
    meaning: "get (auxiliary verb)",
  },
  166: { character: "灯", pinyin: "dēng", meaning: "lamp" },
  167: { character: "登机牌", pinyin: "dēng jī pái", meaning: "boarding pass" },
  168: {
    character: "等（助词）",
    pinyin: "děng (zhùcí)",
    meaning: "wait (particle)",
  },
  169: {
    character: "等（动词）",
    pinyin: "děng (dòngcí)",
    meaning: "wait (verb)",
  },
  170: { character: "低", pinyin: "dī", meaning: "Low" },
  171: { character: "底", pinyin: "dǐ", meaning: "end" },
  172: { character: "地点", pinyin: "dìdiǎn", meaning: "Place" },
  173: { character: "地方", pinyin: "dìfāng", meaning: "place" },
  174: { character: "地球", pinyin: "dìqiú", meaning: "Earth" },
  175: { character: "地铁", pinyin: "dìtiě", meaning: "subway" },
  176: { character: "地图", pinyin: "dìtú", meaning: "map" },
  177: { character: "地址", pinyin: "dìzhǐ", meaning: "address" },
  178: { character: "弟弟", pinyin: "dìdì", meaning: "younger brother" },
  179: { character: "第一", pinyin: "dì yī", meaning: "First" },
  180: { character: "点", pinyin: "diǎn", meaning: "point" },
  181: { character: "电脑", pinyin: "diànnǎo", meaning: "computer" },
  182: { character: "电视", pinyin: "diànshì", meaning: "television" },
  183: { character: "电梯", pinyin: "diàntī", meaning: "elevator" },
  184: { character: "电影", pinyin: "diànyǐng", meaning: "Movie" },
  185: { character: "电子邮件", pinyin: "diànzǐ yóujiàn", meaning: "e-mail" },
  186: { character: "调查", pinyin: "diàochá", meaning: "investigation" },
  187: { character: "掉", pinyin: "diào", meaning: "Lose" },
  188: { character: "丢", pinyin: "diū", meaning: "leave" },
  189: { character: "东", pinyin: "dōng", meaning: "East" },
  190: { character: "东西", pinyin: "dōngxī", meaning: "thing" },
  191: { character: "冬", pinyin: "dōng", meaning: "winter" },
  192: { character: "懂", pinyin: "dǒng", meaning: "Understand" },
  193: { character: "动物", pinyin: "dòngwù", meaning: "animal" },
  194: { character: "动作", pinyin: "dòngzuò", meaning: "action" },
  195: { character: "都", pinyin: "dōu", meaning: "All" },
  196: { character: "读", pinyin: "dú", meaning: "read" },
  197: { character: "堵车", pinyin: "dǔchē", meaning: "traffic jam" },
  198: { character: "肚子", pinyin: "dùzi", meaning: "abdomen" },
  199: { character: "短", pinyin: "duǎn", meaning: "short" },
  200: { character: "短信", pinyin: "duǎnxìn", meaning: "Short message" },
  201: { character: "段", pinyin: "duàn", meaning: "part" },
  202: { character: "锻炼", pinyin: "duànliàn", meaning: "exercise" },
  203: {
    character: "对（形容词）",
    pinyin: "duì (xíngróngcí)",
    meaning: "right (adjective)",
  },
  204: {
    character: "对（介词）",
    pinyin: "duì (jiècí)",
    meaning: "right (preposition)",
  },
  205: { character: "对不起", pinyin: "duìbùqǐ", meaning: "sorry" },
  206: { character: "对话", pinyin: "duìhuà", meaning: "dialogue" },
  207: { character: "对面", pinyin: "duìmiàn", meaning: "opposite" },
  208: { character: "对于", pinyin: "duìyú", meaning: "for" },
  209: { character: "多", pinyin: "duō", meaning: "many" },
  210: { character: "多么", pinyin: "duōme", meaning: "how" },
  211: { character: "多少", pinyin: "duōshǎo", meaning: "How many" },
  212: { character: "饿", pinyin: "è", meaning: "hungry" },
  213: { character: "儿童", pinyin: "értóng", meaning: "child" },
  214: { character: "儿子", pinyin: "érzi", meaning: "son" },
  215: { character: "而", pinyin: "ér", meaning: "and" },
  216: { character: "耳朵", pinyin: "ěrduǒ", meaning: "ear" },
  217: { character: "二", pinyin: "èr", meaning: "two" },
  218: { character: "发", pinyin: "fā", meaning: "send" },
  219: { character: "发烧", pinyin: "fāshāo", meaning: "fever" },
  220: { character: "发生", pinyin: "fāshēng", meaning: "occur" },
  221: { character: "发现", pinyin: "fāxiàn", meaning: "Find" },
  222: { character: "发展", pinyin: "fāzhǎn", meaning: "develop" },
  223: { character: "法律", pinyin: "fǎlǜ", meaning: "law" },
  224: { character: "翻译", pinyin: "fānyì", meaning: "translate" },
  225: { character: "烦恼", pinyin: "fánnǎo", meaning: "trouble" },
  226: { character: "反对", pinyin: "fǎnduì", meaning: "be opposed to" },
  227: { character: "饭店", pinyin: "fàndiàn", meaning: "restaurant" },
  228: { character: "方便", pinyin: "fāngbiàn", meaning: "convenient" },
  229: { character: "方法", pinyin: "fāngfǎ", meaning: "method" },
  230: { character: "方面", pinyin: "fāngmiàn", meaning: "aspect" },
  231: { character: "方向", pinyin: "fāngxiàng", meaning: "direction" },
  232: { character: "房东", pinyin: "fángdōng", meaning: "landlord" },
  233: { character: "房间", pinyin: "fángjiān", meaning: "Room" },
  234: { character: "放", pinyin: "fàng", meaning: "put" },
  235: { character: "放弃", pinyin: "fàngqì", meaning: "give up" },
  236: {
    character: "放暑假",
    pinyin: "fàng shǔjià",
    meaning: "summer holiday",
  },
  237: { character: "放松", pinyin: "fàngsōng", meaning: "Relax" },
  238: { character: "放心", pinyin: "fàngxīn", meaning: "rest assured" },
  239: { character: "飞机", pinyin: "fēijī", meaning: "airplane" },
  240: { character: "非常", pinyin: "fēicháng", meaning: "Very" },
  241: { character: "分", pinyin: "fēn", meaning: "Minute" },
  242: { character: "分钟", pinyin: "fēnzhōng", meaning: "minute" },
  243: { character: "份", pinyin: "fèn", meaning: "share" },
  244: { character: "丰富", pinyin: "fēngfù", meaning: "Rich" },
  245: { character: "否则", pinyin: "fǒuzé", meaning: "otherwise" },
  246: { character: "服务员", pinyin: "fúwùyuán", meaning: "waiter" },
  247: { character: "符合", pinyin: "fúhé", meaning: "meets the" },
  248: { character: "父亲", pinyin: "fùqīn", meaning: "Father" },
  249: { character: "付款", pinyin: "fùkuǎn", meaning: "Payment" },
  250: { character: "负责", pinyin: "fùzé", meaning: "Responsible" },
  251: { character: "附近", pinyin: "fùjìn", meaning: "nearby" },
  252: { character: "复习", pinyin: "fùxí", meaning: "review" },
  253: { character: "复印", pinyin: "fùyìn", meaning: "copy" },
  254: { character: "复杂", pinyin: "fùzá", meaning: "complex" },
  255: { character: "富", pinyin: "fù", meaning: "rich" },
  256: { character: "改变", pinyin: "gǎibiàn", meaning: "Change" },
  257: { character: "干杯", pinyin: "gānbēi", meaning: "cheers" },
  258: { character: "干净", pinyin: "gānjìng", meaning: "clean" },
  259: { character: "赶", pinyin: "gǎn", meaning: "rush" },
  260: { character: "敢", pinyin: "gǎn", meaning: "dare" },
  261: { character: "感动", pinyin: "gǎndòng", meaning: "move" },
  262: { character: "感觉", pinyin: "gǎnjué", meaning: "Feel" },
  263: { character: "感冒", pinyin: "gǎnmào", meaning: "cold" },
  264: { character: "感情", pinyin: "gǎnqíng", meaning: "emotion" },
  265: { character: "感谢", pinyin: "gǎnxiè", meaning: "grateful" },
  266: { character: "感兴趣", pinyin: "gǎn xìngqù", meaning: "interested" },
  267: { character: "干", pinyin: "gàn", meaning: "Dry" },
  268: { character: "刚", pinyin: "gāng", meaning: "Just" },
  269: { character: "刚才", pinyin: "gāng cái", meaning: "Just now" },
  270: { character: "高", pinyin: "gāo", meaning: "high" },
  271: { character: "高速公路", pinyin: "gāosù gōnglù", meaning: "highway" },
  272: { character: "高兴", pinyin: "gāoxìng", meaning: "Happy" },
  273: { character: "告诉", pinyin: "gàosù", meaning: "Tell" },
  274: { character: "哥哥", pinyin: "gēgē", meaning: "elder brother" },
  275: { character: "胳膊", pinyin: "gēbó", meaning: "arm" },
  276: { character: "个", pinyin: "gè", meaning: "individual" },
  277: { character: "个子", pinyin: "gèzi", meaning: "stature" },
  278: { character: "各", pinyin: "gè", meaning: "each" },
  279: { character: "给", pinyin: "gěi", meaning: "Give" },
  280: { character: "根据", pinyin: "gēnjù", meaning: "according to" },
  281: { character: "跟", pinyin: "gēn", meaning: "and" },
  282: { character: "更", pinyin: "gèng", meaning: "Even" },
  283: { character: "工资", pinyin: "gōngzī", meaning: "salary" },
  284: { character: "工作", pinyin: "gōngzuò", meaning: "Work" },
  285: { character: "公共汽车", pinyin: "gōnggòng qìchē", meaning: "bus" },
  286: { character: "公斤", pinyin: "gōngjīn", meaning: "Kilogram" },
  287: { character: "公里", pinyin: "gōnglǐ", meaning: "kilometer" },
  288: { character: "公司", pinyin: "gōngsī", meaning: "company" },
  289: { character: "公园", pinyin: "gōngyuán", meaning: "garden" },
  290: { character: "功夫", pinyin: "gōngfū", meaning: "effort" },
  291: { character: "共同", pinyin: "gòngtóng", meaning: "common" },
  292: { character: "狗", pinyin: "gǒu", meaning: "dog" },
  293: { character: "购物", pinyin: "gòuwù", meaning: "Shopping" },
  294: { character: "够", pinyin: "gòu", meaning: "enough" },
  295: { character: "估计", pinyin: "gūjì", meaning: "estimate" },
  296: { character: "鼓励", pinyin: "gǔlì", meaning: "encourage" },
  297: { character: "故事", pinyin: "gùshì", meaning: "story" },
  298: { character: "故意", pinyin: "gùyì", meaning: "deliberately" },
  299: { character: "顾客", pinyin: "gùkè", meaning: "customer" },
  300: { character: "刮风", pinyin: "Guā fēng", meaning: "windy" },
  301: { character: "挂", pinyin: "guà", meaning: "hang" },
  302: { character: "关", pinyin: "guān", meaning: "close" },
  303: { character: "关键", pinyin: "guānjiàn", meaning: "The essential" },
  304: { character: "关系", pinyin: "guānxì", meaning: "relation" },
  305: { character: "关心", pinyin: "guānxīn", meaning: "care" },
  306: { character: "关于", pinyin: "guānyú", meaning: "about" },
  307: { character: "观众", pinyin: "guānzhòng", meaning: "audience" },
  308: { character: "管理", pinyin: "guǎnlǐ", meaning: "manage" },
  309: { character: "光", pinyin: "guāng", meaning: "Light" },
  310: { character: "广播", pinyin: "guǎngbò", meaning: "broadcast" },
  311: { character: "广告", pinyin: "guǎnggào", meaning: "advertise" },
  312: { character: "逛", pinyin: "guàng", meaning: "visit" },
  313: { character: "规定", pinyin: "guīdìng", meaning: "Regulation" },
  314: { character: "贵", pinyin: "guì", meaning: "expensive" },
  315: {
    character: "国籍",
    pinyin: "guójí",
    meaning: "Country of Citizenship",
  },
  316: { character: "国际", pinyin: "guójì", meaning: "internationality" },
  317: { character: "国家", pinyin: "guójiā", meaning: "nation" },
  318: { character: "果汁", pinyin: "guǒ zhī", meaning: "juice" },
  319: {
    character: "过（动词）",
    pinyin: "guò (dòngcí)",
    meaning: "pass (verb)",
  },
  320: { character: "过程", pinyin: "guòchéng", meaning: "process" },
  321: { character: "过去", pinyin: "guò qù", meaning: "past" },
  322: {
    character: "过（助词）",
    pinyin: "guò (zhùcí)",
    meaning: "passed (particle)",
  },
  323: {
    character: "还（副词）",
    pinyin: "hái (fùcí)",
    meaning: "also (adverb)",
  },
  324: { character: "还是", pinyin: "háishì", meaning: "still" },
  325: { character: "孩子", pinyin: "háizi", meaning: "child" },
  326: { character: "海洋", pinyin: "hǎiyáng", meaning: "ocean" },
  327: { character: "害怕", pinyin: "hàipà", meaning: "Fear" },
  328: { character: "害羞", pinyin: "hàixiū", meaning: "shy" },
  329: { character: "寒假", pinyin: "hánjià", meaning: "winter vacation" },
  330: { character: "汉语", pinyin: "hànyǔ", meaning: "Chinese" },
  331: { character: "汗", pinyin: "hàn", meaning: "sweat" },
  332: { character: "航班", pinyin: "hángbān", meaning: "flight" },
  333: { character: "好", pinyin: "hǎo", meaning: "it is good" },
  334: { character: "好吃", pinyin: "hào chī", meaning: "good to eat" },
  335: { character: "好处", pinyin: "hǎochù", meaning: "benefit" },
  336: { character: "好像", pinyin: "hǎoxiàng", meaning: "like" },
  337: { character: "号", pinyin: "hào", meaning: "day of a month, number" },
  338: { character: "号码", pinyin: "hàomǎ", meaning: "Number" },
  339: { character: "喝", pinyin: "hē", meaning: "drink" },
  340: { character: "合格", pinyin: "hégé", meaning: "qualified" },
  341: { character: "合适", pinyin: "héshì", meaning: "Suitable" },
  342: { character: "和", pinyin: "hé", meaning: "and" },
  343: { character: "盒子", pinyin: "hézi", meaning: "Box" },
  344: { character: "黑", pinyin: "hēi", meaning: "black" },
  345: { character: "黑板", pinyin: "hēibǎn", meaning: "blackboard" },
  346: { character: "很", pinyin: "hěn", meaning: "very" },
  347: { character: "红", pinyin: "hóng", meaning: "red" },
  348: { character: "后悔", pinyin: "hòuhuǐ", meaning: "regret" },
  349: { character: "后来", pinyin: "hòulái", meaning: "later" },
  350: { character: "后面", pinyin: "hòumiàn", meaning: "Behind" },
  351: { character: "厚", pinyin: "hòu", meaning: "thick" },
  352: { character: "互联网", pinyin: "hùliánwǎng", meaning: "the Internet" },
  353: { character: "互相", pinyin: "hùxiāng", meaning: "each other" },
  354: { character: "护士", pinyin: "hùshì", meaning: "Nurse" },
  355: { character: "护照", pinyin: "hùzhào", meaning: "passport" },
  356: {
    character: "花（名词）",
    pinyin: "huā (míngcí)",
    meaning: "flower (noun)",
  },
  357: {
    character: "花（动词）",
    pinyin: "huā (dòngcí)",
    meaning: "flower (verb)",
  },
  358: { character: "画", pinyin: "huà", meaning: "painting" },
  359: { character: "怀疑", pinyin: "huáiyí", meaning: "Suspect" },
  360: { character: "坏", pinyin: "huài", meaning: "Bad" },
  361: { character: "欢迎", pinyin: "huānyíng", meaning: "welcome" },
  362: {
    character: "还（动词）",
    pinyin: "hái (dòngcí)",
    meaning: "also (verb)",
  },
  363: { character: "环境", pinyin: "huánjìng", meaning: "surroundings" },
  364: { character: "换", pinyin: "huàn", meaning: "Change" },
  365: { character: "黄河", pinyin: "huánghé", meaning: "Yellow River" },
  366: { character: "回", pinyin: "huí", meaning: "back, return" },
  367: { character: "回答", pinyin: "huídá", meaning: "Reply" },
  368: { character: "回忆", pinyin: "huíyì", meaning: "Memories" },
  369: { character: "会", pinyin: "huì", meaning: "meeting, can, be able to" },
  370: { character: "会议", pinyin: "huìyì", meaning: "Meeting" },
  371: { character: "活动", pinyin: "huódòng", meaning: "Activity" },
  372: { character: "活泼", pinyin: "huópō", meaning: "Lively" },
  373: { character: "火", pinyin: "huǒ", meaning: "fire" },
  374: { character: "火车站", pinyin: "huǒchē zhàn", meaning: "TRAIN STATION" },
  375: { character: "或者", pinyin: "huòzhě", meaning: "or" },
  376: { character: "获得", pinyin: "huòdé", meaning: "get" },
  377: { character: "几乎", pinyin: "jīhū", meaning: "almost" },
  378: { character: "机场", pinyin: "jīchǎng", meaning: "Airport" },
  379: { character: "机会", pinyin: "jīhuì", meaning: "Chance" },
  380: { character: "鸡蛋", pinyin: "jīdàn", meaning: "egg" },
  381: { character: "积极", pinyin: "jījí", meaning: "positive" },
  382: { character: "积累", pinyin: "jīlěi", meaning: "accumulation" },
  383: { character: "基础", pinyin: "jīchǔ", meaning: "Base" },
  384: { character: "激动", pinyin: "jīdòng", meaning: "Excited" },
  385: { character: "及时", pinyin: "jíshí", meaning: "timely" },
  386: { character: "极", pinyin: "jí", meaning: "pole, utmost" },
  387: { character: "即使", pinyin: "jíshǐ", meaning: "even though" },
  388: { character: "几", pinyin: "jǐ", meaning: "Several" },
  389: { character: "计划", pinyin: "jìhuà", meaning: "plan" },
  390: { character: "记得", pinyin: "jìdé", meaning: "Remember" },
  391: { character: "记者", pinyin: "jìzhě", meaning: "reporter" },
  392: { character: "技术", pinyin: "jìshù", meaning: "technology" },
  393: { character: "季节", pinyin: "jìjié", meaning: "season" },
  394: { character: "既然", pinyin: "jìrán", meaning: "now that" },
  395: { character: "继续", pinyin: "jìxù", meaning: "continue" },
  396: { character: "寄", pinyin: "jì", meaning: "send" },
  397: { character: "加班", pinyin: "jiābān", meaning: "work overtime" },
  398: { character: "加油站", pinyin: "jiāyóu zhàn", meaning: "Gas station" },
  399: { character: "家", pinyin: "jiā", meaning: "Family" },
  400: { character: "家具", pinyin: "jiājù", meaning: "furniture" },
  401: { character: "假", pinyin: "jiǎ", meaning: "Fake" },
  402: { character: "价格", pinyin: "jiàgé", meaning: "price" },
  403: { character: "坚持", pinyin: "jiānchí", meaning: "persist in" },
  404: { character: "检查", pinyin: "jiǎnchá", meaning: "an examination" },
  405: { character: "减肥", pinyin: "jiǎnféi", meaning: "lose weight" },
  406: { character: "减少", pinyin: "jiǎnshǎo", meaning: "reduce" },
  407: { character: "简单", pinyin: "jiǎndān", meaning: "Simple" },
  408: { character: "见面", pinyin: "jiànmiàn", meaning: "Meet" },
  409: { character: "件", pinyin: "jiàn", meaning: "piece" },
  410: { character: "建议", pinyin: "jiànyì", meaning: "Suggest" },
  411: { character: "健康", pinyin: "jiànkāng", meaning: "healthy" },
  412: { character: "将来", pinyin: "jiānglái", meaning: "future" },
  413: { character: "讲", pinyin: "jiǎng", meaning: "speak" },
  414: { character: "奖金", pinyin: "jiǎngjīn", meaning: "bonus" },
  415: { character: "降低", pinyin: "jiàngdī", meaning: "reduce" },
  416: { character: "降落", pinyin: "jiàngluò", meaning: "landing" },
  417: { character: "交", pinyin: "jiāo", meaning: "pay" },
  418: { character: "交流", pinyin: "jiāoliú", meaning: "comminicate" },
  419: { character: "交通", pinyin: "jiāotōng", meaning: "transportation" },
  420: { character: "郊区", pinyin: "jiāoqū", meaning: "suburbs" },
  421: { character: "骄傲", pinyin: "jiāo'ào", meaning: "pride" },
  422: { character: "教", pinyin: "jiào", meaning: "teach" },
  423: { character: "角", pinyin: "jiǎo", meaning: "horn" },
  424: { character: "饺子", pinyin: "jiǎozi", meaning: "dumpling" },
  425: { character: "脚", pinyin: "jiǎo", meaning: "foot" },
  426: { character: "叫", pinyin: "jiào", meaning: "Call" },
  427: { character: "教室", pinyin: "jiàoshì", meaning: "classroom" },
  428: { character: "教授", pinyin: "jiàoshòu", meaning: "professor" },
  429: { character: "教育", pinyin: "jiàoyù", meaning: "educate" },
  430: { character: "接", pinyin: "jiē", meaning: "catch" },
  431: { character: "接受", pinyin: "jiēshòu", meaning: "accept" },
  432: { character: "接着", pinyin: "jiēzhe", meaning: "then" },
  433: { character: "街道", pinyin: "jiēdào", meaning: "street" },
  434: { character: "节", pinyin: "jié", meaning: "Festival" },
  435: { character: "节目", pinyin: "jiémù", meaning: "programme" },
  436: { character: "节日", pinyin: "jiérì", meaning: "festival" },
  437: { character: "节约", pinyin: "jiéyuē", meaning: "saving" },
  438: { character: "结果", pinyin: "jiéguǒ", meaning: "result" },
  439: { character: "结婚", pinyin: "jiéhūn", meaning: "marry" },
  440: { character: "结束", pinyin: "jiéshù", meaning: "Finish" },
  441: { character: "姐姐", pinyin: "jiějiě", meaning: "elder sister" },
  442: { character: "解决", pinyin: "jiějué", meaning: "solve" },
  443: { character: "解释", pinyin: "jiěshì", meaning: "explain" },
  444: { character: "介绍", pinyin: "jièshào", meaning: "introduce" },
  445: { character: "借", pinyin: "jiè", meaning: "borrow" },
  446: { character: "今天", pinyin: "jīntiān", meaning: "today" },
  447: { character: "尽管", pinyin: "jǐnguǎn", meaning: "although" },
  448: { character: "紧张", pinyin: "jǐnzhāng", meaning: "nervous" },
  449: { character: "进", pinyin: "jìn", meaning: "Enter" },
  450: { character: "进行", pinyin: "Jìnxíng", meaning: "conduct" },
  451: { character: "近", pinyin: "jìn", meaning: "close" },
  452: { character: "禁止", pinyin: "jìnzhǐ", meaning: "prohibit" },
  453: { character: "京剧", pinyin: "jīngjù", meaning: "Peking Opera" },
  454: { character: "经常", pinyin: "jīngcháng", meaning: "often" },
  455: { character: "经过", pinyin: "jīngguò", meaning: "go through" },
  456: { character: "经济", pinyin: "jīngjì", meaning: "economy" },
  457: { character: "经理", pinyin: "jīnglǐ", meaning: "manager" },
  458: { character: "经历", pinyin: "jīnglì", meaning: "experience" },
  459: { character: "经验", pinyin: "jīngyàn", meaning: "experience" },
  460: { character: "精彩", pinyin: "jīngcǎi", meaning: "Wonderful" },
  461: { character: "景色", pinyin: "jǐngsè", meaning: "scenery" },
  462: { character: "警察", pinyin: "jǐngchá", meaning: "police" },
  463: { character: "竞争", pinyin: "jìngzhēng", meaning: "compete" },
  464: { character: "竟然", pinyin: "jìngrán", meaning: "it turns out" },
  465: { character: "镜子", pinyin: "jìngzi", meaning: "Mirror" },
  466: { character: "究竟", pinyin: "jiùjìng", meaning: "after all" },
  467: { character: "九", pinyin: "jiǔ", meaning: "Nine" },
  468: { character: "久", pinyin: "jiǔ", meaning: "Long" },
  469: { character: "旧", pinyin: "jiù", meaning: "old" },
  470: { character: "就", pinyin: "jiù", meaning: "At once" },
  471: { character: "举", pinyin: "jǔ", meaning: "lift" },
  472: { character: "举办", pinyin: "jǔbàn", meaning: "hold" },
  473: { character: "举行", pinyin: "jǔxíng", meaning: "held" },
  474: { character: "句子", pinyin: "jùzi", meaning: "sentence" },
  475: { character: "拒绝", pinyin: "jùjué", meaning: "reject" },
  476: { character: "距离", pinyin: "jùlí", meaning: "distance" },
  477: { character: "聚会", pinyin: "jùhuì", meaning: "reunion" },
  478: { character: "决定", pinyin: "juédìng", meaning: "Decide" },
  479: { character: "觉得", pinyin: "juédé", meaning: "feel" },
  480: { character: "咖啡", pinyin: "kāfēi", meaning: "coffee" },
  481: { character: "开", pinyin: "kāi", meaning: "open" },
  482: { character: "开始", pinyin: "kāishǐ", meaning: "start" },
  483: { character: "开玩笑", pinyin: "kāiwánxiào", meaning: "joke" },
  484: { character: "开心", pinyin: "kāixīn", meaning: "happy" },
  485: { character: "看", pinyin: "kàn", meaning: "Look" },
  486: { character: "看法", pinyin: "kànfǎ", meaning: "view" },
  487: { character: "看见", pinyin: "kànjiàn", meaning: "see" },
  488: { character: "考虑", pinyin: "kǎolǜ", meaning: "consider" },
  489: { character: "考试", pinyin: "kǎoshì", meaning: "take an exam" },
  490: { character: "烤鸭", pinyin: "kǎoyā", meaning: "Roast Duck" },
  491: { character: "科学", pinyin: "kēxué", meaning: "science" },
  492: { character: "棵", pinyin: "kē", meaning: "tree" },
  493: { character: "咳嗽", pinyin: "késòu", meaning: "cough" },
  494: { character: "可爱", pinyin: "kě'ài", meaning: "cute" },
  495: { character: "可怜", pinyin: "kělián", meaning: "Pitiful" },
  496: { character: "可能", pinyin: "kěnéng", meaning: "possible" },
  497: { character: "可是", pinyin: "kěshì", meaning: "But" },
  498: { character: "可惜", pinyin: "kěxí", meaning: "pity" },
  499: { character: "可以", pinyin: "kěyǐ", meaning: "Can" },
  500: { character: "渴", pinyin: "kě", meaning: "thirsty" },
  501: { character: "刻", pinyin: "kè", meaning: "carve" },
  502: { character: "客人", pinyin: "kèrén", meaning: "guest" },
  503: { character: "客厅", pinyin: "kètīng", meaning: "living room" },
  504: { character: "课", pinyin: "kè", meaning: "class" },
  505: { character: "肯定", pinyin: "kěndìng", meaning: "affim" },
  506: { character: "空", pinyin: "kōng", meaning: "null" },
  507: { character: "空气", pinyin: "kōngqì", meaning: "Air" },
  508: { character: "空调", pinyin: "kòngtiáo", meaning: "air conditioner" },
  509: { character: "恐怕", pinyin: "kǒngpà", meaning: "I am afraid" },
  510: { character: "口", pinyin: "kǒu", meaning: "mouth" },
  511: { character: "哭", pinyin: "kū", meaning: "cry" },
  512: { character: "苦", pinyin: "kǔ", meaning: "bitter" },
  513: { character: "裤子", pinyin: "kùzi", meaning: "Pants" },
  514: { character: "块", pinyin: "kuài", meaning: "piece" },
  515: { character: "快", pinyin: "kuài", meaning: "quick" },
  516: { character: "快乐", pinyin: "kuàilè", meaning: "hapiness" },
  517: { character: "筷子", pinyin: "kuàizi", meaning: "Chopsticks" },
  518: {
    character: "矿泉水",
    pinyin: "kuàngquán shuǐ",
    meaning: "mineral water",
  },
  519: { character: "困", pinyin: "kùn", meaning: "Sleepy" },
  520: { character: "困难", pinyin: "kùnnán", meaning: "difficulty" },
  521: { character: "垃圾桶", pinyin: "lèsè tǒng", meaning: "garbage can" },
  522: { character: "拉", pinyin: "lā", meaning: "pull" },
  523: { character: "辣", pinyin: "là", meaning: "hot" },
  524: { character: "来", pinyin: "lái", meaning: "Come" },
  525: { character: "来不及", pinyin: "láibují", meaning: "too late" },
  526: { character: "来得及", pinyin: "láidéjí", meaning: "in time" },
  527: { character: "来自", pinyin: "láizì", meaning: "from" },
  528: { character: "蓝", pinyin: "lán", meaning: "blue" },
  529: { character: "懒", pinyin: "lǎn", meaning: "lazy" },
  530: { character: "浪费", pinyin: "làngfèi", meaning: "waste" },
  531: { character: "浪漫", pinyin: "làngmàn", meaning: "romantic" },
  532: { character: "老", pinyin: "lǎo", meaning: "old" },
  533: { character: "老虎", pinyin: "lǎohǔ", meaning: "tiger" },
  534: { character: "老师", pinyin: "lǎo shī", meaning: "teacher" },
  535: { character: "了", pinyin: "le", meaning: "span" },
  536: { character: "累", pinyin: "lèi", meaning: "tired" },
  537: { character: "冷", pinyin: "lěng", meaning: "cold" },
  538: { character: "冷静", pinyin: "lěngjìng", meaning: "calm" },
  539: { character: "离", pinyin: "lí", meaning: "Leave" },
  540: { character: "离开", pinyin: "líkāi", meaning: "leave" },
  541: { character: "礼拜天", pinyin: "lǐbài tiān", meaning: "Sunday" },
  542: { character: "礼貌", pinyin: "lǐmào", meaning: "polite" },
  543: { character: "礼物", pinyin: "lǐwù", meaning: "Gift,  present" },
  544: { character: "里", pinyin: "lǐ", meaning: "inside" },
  545: { character: "理发", pinyin: "lǐfǎ", meaning: "haircut" },
  546: { character: "理解", pinyin: "lǐjiě", meaning: "understand" },
  547: { character: "理想", pinyin: "lǐxiǎng", meaning: "ideal" },
  548: { character: "力气", pinyin: "lì qì", meaning: "strength" },
  549: { character: "历史", pinyin: "lìshǐ", meaning: "history" },
  550: { character: "厉害", pinyin: "lìhài", meaning: "sharp" },
  551: { character: "例如", pinyin: "lìrú", meaning: "E.g" },
  552: { character: "俩", pinyin: "liǎ", meaning: "both" },
  553: { character: "连", pinyin: "lián", meaning: "even" },
  554: { character: "联系", pinyin: "liánxì", meaning: "connect" },
  555: { character: "脸", pinyin: "liǎn", meaning: "Face" },
  556: { character: "练习", pinyin: "liànxí", meaning: "practise" },
  557: { character: "凉快", pinyin: "liángkuai", meaning: "cool" },
  558: { character: "两", pinyin: "liǎng", meaning: "two" },
  559: { character: "辆", pinyin: "liàng", meaning: "vehicle" },
  560: { character: "聊天", pinyin: "liáotiān", meaning: "to chat with" },
  561: { character: "了解", pinyin: "liǎojiě", meaning: "learn" },
  562: { character: "邻居", pinyin: "línjū", meaning: "Neighbor" },
  563: { character: "零", pinyin: "líng", meaning: "zero" },
  564: { character: "零钱", pinyin: "líng qián", meaning: "small change" },
  565: { character: "另外", pinyin: "lìngwài", meaning: "in addition" },
  566: { character: "留", pinyin: "liú", meaning: "Keep" },
  567: { character: "留学", pinyin: "liúxué", meaning: "study abroad" },
  568: { character: "流利", pinyin: "liúlì", meaning: "fluent" },
  569: { character: "流行", pinyin: "liúxíng", meaning: "Popularity" },
  570: { character: "六", pinyin: "liù", meaning: "six" },
  571: { character: "楼", pinyin: "lóu", meaning: "building" },
  572: { character: "路", pinyin: "lù", meaning: "road" },
  573: { character: "旅行", pinyin: "lǚxíng", meaning: "travel" },
  574: { character: "旅游", pinyin: "lǚyóu", meaning: "travel" },
  575: { character: "律师", pinyin: "lǜshī", meaning: "lawyer" },
  576: { character: "绿", pinyin: "lǜ", meaning: "green" },
  577: { character: "乱", pinyin: "luàn", meaning: "chaos" },
  578: { character: "妈妈", pinyin: "māmā", meaning: "Mother" },
  579: { character: "麻烦", pinyin: "máfan", meaning: "trouble" },
  580: { character: "马", pinyin: "mǎ", meaning: "horse" },
  581: { character: "马虎", pinyin: "mǎhǔ", meaning: "careless" },
  582: { character: "马上", pinyin: "mǎshàng", meaning: "immediately" },
  583: { character: "吗", pinyin: "ma", meaning: "Is it?(question particle)" },
  584: { character: "买", pinyin: "mǎi", meaning: "purchase, buy" },
  585: { character: "卖", pinyin: "mài", meaning: "Sell" },
  586: { character: "满", pinyin: "mǎn", meaning: "Full" },
  587: { character: "满意", pinyin: "mǎnyì", meaning: "satisfy" },
  588: { character: "慢", pinyin: "màn", meaning: "slow" },
  589: { character: "忙", pinyin: "máng", meaning: "busy" },
  590: { character: "猫", pinyin: "māo", meaning: "cat" },
  591: { character: "毛", pinyin: "máo", meaning: "hair" },
  592: { character: "毛巾", pinyin: "máojīn", meaning: "towel" },
  593: { character: "帽子", pinyin: "màozi", meaning: "hat" },
  594: { character: "没关系", pinyin: "méiguānxì", meaning: "It's ok" },
  595: { character: "没有", pinyin: "méiyǒu", meaning: "No" },
  596: { character: "每", pinyin: "měi", meaning: "Every" },
  597: { character: "美丽", pinyin: "měilì", meaning: "beauty" },
  598: { character: "妹妹", pinyin: "mèimei", meaning: "younger sister" },
  599: { character: "门", pinyin: "mén", meaning: "Door" },
  600: { character: "梦", pinyin: "Mèng", meaning: "Dream" },
  601: { character: "迷路", pinyin: "mílù", meaning: "get lost" },
  602: { character: "米", pinyin: "mǐ", meaning: "Meter" },
  603: { character: "米饭", pinyin: "mǐfàn", meaning: "rice" },
  604: { character: "密码", pinyin: "mìmǎ", meaning: "password" },
  605: { character: "免费", pinyin: "miǎnfèi", meaning: "free" },
  606: { character: "面包", pinyin: "miànbāo", meaning: "bread" },
  607: { character: "面条", pinyin: "miàntiáo", meaning: "Noodle" },
  608: { character: "秒", pinyin: "miǎo", meaning: "second" },
  609: { character: "民族", pinyin: "mínzú", meaning: "nationality" },
  610: { character: "名字", pinyin: "míngzì", meaning: "name" },
  611: { character: "明白", pinyin: "míngbái", meaning: "clear" },
  612: { character: "明天", pinyin: "míngtiān", meaning: "tomorrow" },
  613: { character: "母亲", pinyin: "mǔqīn", meaning: "Mother" },
  614: { character: "目的", pinyin: "mùdì", meaning: "Purpose" },
  615: { character: "拿", pinyin: "ná", meaning: "take" },
  616: { character: "哪", pinyin: "nǎ", meaning: "where" },
  617: { character: "哪儿", pinyin: "nǎ'er", meaning: "where" },
  618: { character: "那", pinyin: "nà", meaning: "That" },
  619: { character: "奶奶", pinyin: "nǎinai", meaning: "grandmother" },
  620: { character: "耐心", pinyin: "nàixīn", meaning: "patience" },
  621: { character: "男", pinyin: "nán", meaning: "male" },
  622: { character: "南", pinyin: "nán", meaning: "South" },
  623: { character: "难", pinyin: "nán", meaning: "Disaster" },
  624: { character: "难道", pinyin: "nándào", meaning: "Is it" },
  625: { character: "难过", pinyin: "nánguò", meaning: "sad" },
  626: { character: "难受", pinyin: "nánshòu", meaning: "uncomfortable" },
  627: { character: "呢", pinyin: "ne", meaning: "Woolen cloth" },
  628: { character: "内", pinyin: "nèi", meaning: "Inside" },
  629: { character: "内容", pinyin: "nèiróng", meaning: "content" },
  630: { character: "能", pinyin: "néng", meaning: "can" },
  631: { character: "能力", pinyin: "nénglì", meaning: "ability" },
  632: { character: "你", pinyin: "nǐ", meaning: "you" },
  633: { character: "年", pinyin: "nián", meaning: "year" },
  634: { character: "年级", pinyin: "niánjí", meaning: "grade" },
  635: { character: "年龄", pinyin: "niánlíng", meaning: "age" },
  636: { character: "年轻", pinyin: "niánqīng", meaning: "young" },
  637: { character: "鸟", pinyin: "niǎo", meaning: "bird" },
  638: { character: "您", pinyin: "nín", meaning: "you" },
  639: { character: "牛奶", pinyin: "niúnǎi", meaning: "milk" },
  640: { character: "弄", pinyin: "nòng", meaning: "alley" },
  641: { character: "努力", pinyin: "nǔlì", meaning: "Great effort," },
  642: { character: "女", pinyin: "nǚ", meaning: "Female" },
  643: { character: "女儿", pinyin: "nǚ'ér", meaning: "daughter" },
  644: { character: "暖和", pinyin: "nuǎnhuo", meaning: "warm" },
  645: { character: "偶尔", pinyin: "ǒu'ěr", meaning: "Occasionally" },
  646: { character: "爬山", pinyin: "páshān", meaning: "climb mountains" },
  647: { character: "排队", pinyin: "páiduì", meaning: "queue" },
  648: { character: "排列", pinyin: "páiliè", meaning: "arrangement" },
  649: { character: "盘子", pinyin: "pánzi", meaning: "plate" },
  650: { character: "判断", pinyin: "pànduàn", meaning: "judge" },
  651: { character: "旁边", pinyin: "pángbiān", meaning: "beside" },
  652: { character: "胖", pinyin: "pàng", meaning: "fat" },
  653: { character: "跑步", pinyin: "pǎobù", meaning: "Run" },
  654: { character: "陪", pinyin: "péi", meaning: "accompany" },
  655: { character: "朋友", pinyin: "péngyǒu", meaning: "friend" },
  656: { character: "批评", pinyin: "pīpíng", meaning: "criticize" },
  657: { character: "皮肤", pinyin: "pífū", meaning: "skin" },
  658: { character: "皮鞋", pinyin: "píxié", meaning: "leather shoes" },
  659: { character: "啤酒", pinyin: "píjiǔ", meaning: "beer" },
  660: { character: "脾气", pinyin: "píqì", meaning: "temper" },
  661: { character: "篇", pinyin: "piān", meaning: "articles" },
  662: { character: "便宜", pinyin: "piányí", meaning: "Cheap" },
  663: { character: "骗", pinyin: "piàn", meaning: "cheat" },
  664: { character: "票", pinyin: "piào", meaning: "ticket" },
  665: { character: "漂亮", pinyin: "piàoliang", meaning: "pretty" },
  666: { character: "乒乓球", pinyin: "pīngpāng qiú", meaning: "pingpong" },
  667: { character: "平时", pinyin: "píngshí", meaning: "usually" },
  668: { character: "苹果", pinyin: "píngguǒ", meaning: "apple" },
  669: { character: "瓶子", pinyin: "píngzi", meaning: "bottle" },
  670: { character: "破", pinyin: "pò", meaning: "break" },
  671: { character: "葡萄", pinyin: "pútáo", meaning: "Grape" },
  672: { character: "普遍", pinyin: "pǔbiàn", meaning: "universal" },
  673: { character: "普通话", pinyin: "pǔtōnghuà", meaning: "mandarin" },
  674: { character: "七", pinyin: "qī", meaning: "seven" },
  675: { character: "妻子", pinyin: "qīzi", meaning: "wife" },
  676: { character: "其次", pinyin: "qícì", meaning: "Second" },
  677: { character: "其实", pinyin: "qíshí", meaning: "actually" },
  678: { character: "其他", pinyin: "qítā", meaning: "other" },
  679: { character: "其中", pinyin: "qízhōng", meaning: "in" },
  680: { character: "奇怪", pinyin: "qíguài", meaning: "strangeness" },
  681: { character: "骑", pinyin: "qí", meaning: "ride" },
  682: { character: "起床", pinyin: "qǐchuáng", meaning: "get up" },
  683: { character: "起飞", pinyin: "qǐfēi", meaning: "take off" },
  684: { character: "起来", pinyin: "qǐlái", meaning: "stand up" },
  685: { character: "气候", pinyin: "qìhòu", meaning: "climate" },
  686: { character: "千", pinyin: "qiān", meaning: "thousand" },
  687: { character: "千万", pinyin: "qiān wàn", meaning: "ten million" },
  688: { character: "铅笔", pinyin: "qiānbǐ", meaning: "pencil" },
  689: { character: "签证", pinyin: "qiānzhèng", meaning: "visa" },
  690: { character: "前面", pinyin: "qiánmiàn", meaning: "Front" },
  691: { character: "钱", pinyin: "qián", meaning: "money" },
  692: { character: "敲", pinyin: "qiāo", meaning: "knock" },
  693: { character: "桥", pinyin: "qiáo", meaning: "bridge" },
  694: { character: "巧克力", pinyin: "qiǎokèlì", meaning: "chocolate" },
  695: { character: "亲戚", pinyin: "qīnqī", meaning: "relative" },
  696: { character: "轻", pinyin: "qīng", meaning: "light" },
  697: { character: "轻松", pinyin: "qīngsōng", meaning: "easy" },
  698: { character: "清楚", pinyin: "qīngchǔ", meaning: "clear" },
  699: { character: "情况", pinyin: "qíngkuàng", meaning: "Happening" },
  700: { character: "晴", pinyin: "qíng", meaning: "sunny" },
  701: { character: "请", pinyin: "qǐng", meaning: "Please" },
  702: { character: "请假", pinyin: "qǐngjià", meaning: "Ask for leave" },
  703: { character: "穷", pinyin: "qióng", meaning: "poverty" },
  704: { character: "秋", pinyin: "qiū", meaning: "Autumn" },
  705: { character: "区别", pinyin: "qūbié", meaning: "the difference" },
  706: { character: "取", pinyin: "qǔ", meaning: "Pick" },
  707: { character: "去", pinyin: "qù", meaning: "go" },
  708: { character: "去年", pinyin: "qùnián", meaning: "last year" },
  709: { character: "全部", pinyin: "quánbù", meaning: "all" },
  710: { character: "缺点", pinyin: "quēdiǎn", meaning: "shortcoming" },
  711: { character: "缺少", pinyin: "quēshǎo", meaning: "Lack" },
  712: { character: "却", pinyin: "què", meaning: "but" },
  713: { character: "确实", pinyin: "quèshí", meaning: "really" },
  714: { character: "裙子", pinyin: "qúnzi", meaning: "skirt" },
  715: { character: "然而", pinyin: "rán'ér", meaning: "However" },
  716: { character: "然后", pinyin: "ránhòu", meaning: "Then" },
  717: { character: "让", pinyin: "ràng", meaning: "Let" },
  718: { character: "热", pinyin: "rè", meaning: "hot" },
  719: { character: "热闹", pinyin: "rènào", meaning: "lively" },
  720: { character: "热情", pinyin: "rèqíng", meaning: "enthusiasm" },
  721: { character: "人", pinyin: "rén", meaning: "people" },
  722: { character: "认识", pinyin: "rènshí", meaning: "know" },
  723: { character: "认为", pinyin: "rènwéi", meaning: "think" },
  724: { character: "认真", pinyin: "rènzhēn", meaning: "serious" },
  725: { character: "任何", pinyin: "rènhé", meaning: "any" },
  726: { character: "任务", pinyin: "rènwù", meaning: "Task" },
  727: { character: "扔", pinyin: "rēng", meaning: "Throw" },
  728: { character: "仍然", pinyin: "réngrán", meaning: "still" },
  729: { character: "日", pinyin: "rì", meaning: "day" },
  730: { character: "日记", pinyin: "rìjì", meaning: "journal" },
  731: { character: "容易", pinyin: "róngyì", meaning: "easy" },
  732: { character: "如果", pinyin: "rúguǒ", meaning: "if" },
  733: { character: "入口", pinyin: "rùkǒu", meaning: "Entrance" },
  734: { character: "三", pinyin: "sān", meaning: "three" },
  735: { character: "伞", pinyin: "sǎn", meaning: "umbrella" },
  736: { character: "散步", pinyin: "sànbù", meaning: "walk" },
  737: { character: "森林", pinyin: "sēnlín", meaning: "forest" },
  738: { character: "沙发", pinyin: "shāfā", meaning: "sofa" },
  739: { character: "伤心", pinyin: "shāngxīn", meaning: "sad" },
  740: { character: "商店", pinyin: "shāngdiàn", meaning: "shop" },
  741: { character: "商量", pinyin: "shāngliáng", meaning: "discuss" },
  742: { character: "上", pinyin: "shàng", meaning: "superior" },
  743: { character: "上班", pinyin: "shàngbān", meaning: "work" },
  744: { character: "上网", pinyin: "shàngwǎng", meaning: "go online" },
  745: { character: "上午", pinyin: "shàngwǔ", meaning: "morning" },
  746: { character: "稍微", pinyin: "shāowéi", meaning: "a little" },
  747: { character: "勺子", pinyin: "sháozi", meaning: "spoon" },
  748: { character: "少", pinyin: "shǎo", meaning: "few" },
  749: { character: "社会", pinyin: "shèhuì", meaning: "society" },
  750: { character: "谁", pinyin: "Shéi", meaning: "Who" },
  751: { character: "申请", pinyin: "shēnqǐng", meaning: "Application" },
  752: { character: "身体", pinyin: "shēntǐ", meaning: "Body" },
  753: { character: "深", pinyin: "shēn", meaning: "deep" },
  754: { character: "什么", pinyin: "shénme", meaning: "what" },
  755: { character: "甚至", pinyin: "shènzhì", meaning: "even" },
  756: { character: "生病", pinyin: "shēngbìng", meaning: "Get ill" },
  757: { character: "生活", pinyin: "shēnghuó", meaning: "Life" },
  758: { character: "生命", pinyin: "shēngmìng", meaning: "life" },
  759: { character: "生气", pinyin: "shēngqì", meaning: "angry" },
  760: { character: "生日", pinyin: "shēngrì", meaning: "Birthday" },
  761: { character: "生意", pinyin: "shēngyì", meaning: "Business" },
  762: { character: "声音", pinyin: "shēngyīn", meaning: "sound" },
  763: { character: "省", pinyin: "shěng", meaning: "Province" },
  764: { character: "剩", pinyin: "shèng", meaning: "leftover" },
  765: { character: "失败", pinyin: "shībài", meaning: "fail" },
  766: { character: "失望", pinyin: "shīwàng", meaning: "disappointment" },
  767: { character: "师傅", pinyin: "shīfù", meaning: "master" },
  768: { character: "十", pinyin: "shí", meaning: "ten" },
  769: { character: "十分", pinyin: "shí fēn", meaning: "very" },
  770: { character: "时候", pinyin: "shíhòu", meaning: "when" },
  771: { character: "时间", pinyin: "shíjiān", meaning: "time" },
  772: { character: "实际", pinyin: "shíjì", meaning: "actual" },
  773: { character: "实在", pinyin: "shízài", meaning: "really" },
  774: { character: "使", pinyin: "shǐ", meaning: "Make" },
  775: { character: "使用", pinyin: "shǐyòng", meaning: "use" },
  776: { character: "世纪", pinyin: "shìjì", meaning: "century" },
  777: { character: "世界", pinyin: "shìjiè", meaning: "world" },
  778: { character: "事情", pinyin: "shìqíng", meaning: "matter" },
  779: { character: "试", pinyin: "shì", meaning: "try" },
  780: { character: "是", pinyin: "shì", meaning: "Yes" },
  781: { character: "是否", pinyin: "shìfǒu", meaning: "whether" },
  782: { character: "适合", pinyin: "shìhé", meaning: "Suitable for" },
  783: { character: "适应", pinyin: "shìyìng", meaning: "adapt" },
  784: { character: "收", pinyin: "shōu", meaning: "receive" },
  785: { character: "收入", pinyin: "shōurù", meaning: "income" },
  786: { character: "收拾", pinyin: "shōushí", meaning: "tidy" },
  787: { character: "手表", pinyin: "shǒubiǎo", meaning: "watch" },
  788: { character: "手机", pinyin: "shǒujī", meaning: "cell phone" },
  789: { character: "首都", pinyin: "shǒudū", meaning: "capital" },
  790: { character: "首先", pinyin: "shǒuxiān", meaning: "first" },
  791: {
    character: "受不了",
    pinyin: "shòu bùliǎo",
    meaning: "can't stand it",
  },
  792: { character: "受到", pinyin: "shòudào", meaning: "received" },
  793: { character: "售货员", pinyin: "shòuhuòyuán", meaning: "salesperson" },
  794: { character: "瘦", pinyin: "shòu", meaning: "thin" },
  795: { character: "书", pinyin: "shū", meaning: "Book" },
  796: { character: "叔叔", pinyin: "shūshu", meaning: "uncle" },
  797: { character: "舒服", pinyin: "shūfú", meaning: "Comfortable" },
  798: { character: "输", pinyin: "shū", meaning: "lose" },
  799: { character: "熟悉", pinyin: "shúxī", meaning: "familiar" },
  800: { character: "树", pinyin: "shù", meaning: "Tree" },
  801: { character: "数量", pinyin: "shùliàng", meaning: "quantity" },
  802: { character: "数学", pinyin: "shùxué", meaning: "math" },
  803: { character: "数字", pinyin: "shùzì", meaning: "number" },
  804: { character: "刷牙", pinyin: "shuāyá", meaning: "brush teeth" },
  805: { character: "帅", pinyin: "shuài", meaning: "handsome" },
  806: { character: "双", pinyin: "shuāng", meaning: "pair" },
  807: { character: "水", pinyin: "shuǐ", meaning: "water" },
  808: { character: "水果", pinyin: "shuǐguǒ", meaning: "fruit" },
  809: { character: "水平", pinyin: "shuǐpíng", meaning: "Level" },
  810: { character: "睡觉", pinyin: "shuìjiào", meaning: "sleep" },
  811: { character: "顺便", pinyin: "shùnbiàn", meaning: "by the way" },
  812: { character: "顺利", pinyin: "shùnlì", meaning: "smoothly" },
  813: { character: "顺序", pinyin: "shùnxù", meaning: "order" },
  814: { character: "说", pinyin: "shuō", meaning: "Say" },
  815: { character: "说话", pinyin: "shuōhuà", meaning: "say" },
  816: { character: "说明", pinyin: "shuōmíng", meaning: "illustrate" },
  817: { character: "硕士", pinyin: "shuòshì", meaning: "master" },
  818: { character: "司机", pinyin: "sījī", meaning: "driver" },
  819: { character: "死", pinyin: "sǐ", meaning: "die" },
  820: { character: "四", pinyin: "sì", meaning: "Four" },
  821: { character: "送", pinyin: "sòng", meaning: "deliver" },
  822: { character: "速度", pinyin: "sùdù", meaning: "speed" },
  823: { character: "塑料袋", pinyin: "sùliào dài", meaning: "plastic bag" },
  824: { character: "酸", pinyin: "suān", meaning: "acid" },
  825: {
    character: "虽然…但是…",
    pinyin: "suīrán…dànshì…",
    meaning: "However, although…",
  },
  826: { character: "随便", pinyin: "suíbiàn", meaning: "casual" },
  827: { character: "随着", pinyin: "suízhe", meaning: "along with" },
  828: { character: "岁", pinyin: "suì", meaning: "age" },
  829: { character: "孙子", pinyin: "sūnzi", meaning: "grandson" },
  830: { character: "所有", pinyin: "suǒyǒu", meaning: "all" },
  831: { character: "他", pinyin: "tā", meaning: "he" },
  832: { character: "它", pinyin: "tā", meaning: "it" },
  833: { character: "她", pinyin: "tā", meaning: "she" },
  834: { character: "台", pinyin: "tái", meaning: "tower" },
  835: { character: "抬", pinyin: "tái", meaning: "lift" },
  836: { character: "太", pinyin: "tài", meaning: "too" },
  837: { character: "太阳", pinyin: "tàiyáng", meaning: "sun" },
  838: { character: "态度", pinyin: "tàidù", meaning: "manner" },
  839: { character: "谈", pinyin: "tán", meaning: "talk" },
  840: { character: "弹钢琴", pinyin: "dàn gāngqín", meaning: "play piano" },
  841: { character: "汤", pinyin: "tāng", meaning: "soup" },
  842: { character: "糖", pinyin: "táng", meaning: "sugar" },
  843: { character: "躺", pinyin: "tǎng", meaning: "lie" },
  844: { character: "趟", pinyin: "tàng", meaning: "trip" },
  845: { character: "讨论", pinyin: "tǎolùn", meaning: "discuss" },
  846: { character: "讨厌", pinyin: "tǎoyàn", meaning: "Hate" },
  847: { character: "特别", pinyin: "tèbié", meaning: "special" },
  848: { character: "特点", pinyin: "tèdiǎn", meaning: "Features" },
  849: { character: "疼", pinyin: "téng", meaning: "pain" },
  850: { character: "踢足球", pinyin: "tī zúqiú", meaning: "play football" },
  851: { character: "提", pinyin: "tí", meaning: "carry" },
  852: { character: "提高", pinyin: "tígāo", meaning: "improve" },
  853: { character: "提供", pinyin: "tígōng", meaning: "supply" },
  854: { character: "提前", pinyin: "tíqián", meaning: "in advance" },
  855: { character: "提醒", pinyin: "tíxǐng", meaning: "remind" },
  856: { character: "题", pinyin: "tí", meaning: "question" },
  857: { character: "体育", pinyin: "tǐyù", meaning: "physical education" },
  858: { character: "天气", pinyin: "tiānqì", meaning: "weather" },
  859: { character: "甜", pinyin: "tián", meaning: "sweet" },
  860: { character: "填空", pinyin: "tiánkòng", meaning: "fill in the blank" },
  861: { character: "条", pinyin: "tiáo", meaning: "strip" },
  862: { character: "条件", pinyin: "tiáojiàn", meaning: "condition" },
  863: { character: "跳舞", pinyin: "tiàowǔ", meaning: "Dance" },
  864: { character: "听", pinyin: "tīng", meaning: "listen" },
  865: { character: "停", pinyin: "tíng", meaning: "stop" },
  866: { character: "挺", pinyin: "tǐng", meaning: "quite" },
  867: { character: "通过", pinyin: "tōngguò", meaning: "pass" },
  868: { character: "通知", pinyin: "tōngzhī", meaning: "Notice" },
  869: { character: "同情", pinyin: "tóngqíng", meaning: "pity" },
  870: { character: "同时", pinyin: "tóngshí", meaning: "at the same time" },
  871: { character: "同事", pinyin: "tóngshì", meaning: "colleague" },
  872: { character: "同学", pinyin: "tóngxué", meaning: "classmate" },
  873: { character: "同意", pinyin: "tóngyì", meaning: "agree" },
  874: { character: "头发", pinyin: "tóufǎ", meaning: "hair" },
  875: { character: "突然", pinyin: "túrán", meaning: "Sudden" },
  876: { character: "图书馆", pinyin: "túshū guǎn", meaning: "library" },
  877: { character: "推", pinyin: "tuī", meaning: "push" },
  878: { character: "推迟", pinyin: "tuīchí", meaning: "put off" },
  879: { character: "腿", pinyin: "tuǐ", meaning: "leg" },
  880: { character: "脱", pinyin: "tuō", meaning: "take off" },
  881: { character: "袜子", pinyin: "wàzi", meaning: "sock" },
  882: { character: "外", pinyin: "wài", meaning: "outside" },
  883: { character: "完", pinyin: "wán", meaning: "Finish" },
  884: { character: "完成", pinyin: "wánchéng", meaning: "Finish" },
  885: { character: "完全", pinyin: "wánquán", meaning: "completely" },
  886: { character: "玩", pinyin: "wán", meaning: "Play" },
  887: { character: "晚上", pinyin: "wǎnshàng", meaning: "night" },
  888: { character: "碗", pinyin: "wǎn", meaning: "bowl" },
  889: { character: "万", pinyin: "wàn", meaning: "Ten thousand" },
  890: { character: "网球", pinyin: "wǎngqiú", meaning: "tennis" },
  891: { character: "网站", pinyin: "wǎngzhàn", meaning: "website" },
  892: { character: "往", pinyin: "wǎng", meaning: "Past" },
  893: { character: "往往", pinyin: "wǎngwǎng", meaning: "often" },
  894: { character: "忘记", pinyin: "wàngjì", meaning: "forget" },
  895: { character: "危险", pinyin: "wéixiǎn", meaning: "Danger" },
  896: { character: "卫生间", pinyin: "wèishēngjiān", meaning: "bathroom" },
  897: { character: "为", pinyin: "wèi", meaning: "for" },
  898: { character: "为了", pinyin: "wèile", meaning: "for" },
  899: { character: "为什么", pinyin: "wèishéme", meaning: "why" },
  900: { character: "位", pinyin: "Wèi", meaning: "bit" },
  901: { character: "味道", pinyin: "wèidào", meaning: "smell" },
  902: {
    character: "喂（叹词）",
    pinyin: "wèi (tàn cí)",
    meaning: "hello (interjection)",
  },
  903: { character: "温度", pinyin: "wēndù", meaning: "temperature" },
  904: { character: "文化", pinyin: "wénhuà", meaning: "culture" },
  905: { character: "文章", pinyin: "wénzhāng", meaning: "article" },
  906: { character: "问", pinyin: "wèn", meaning: "ask" },
  907: { character: "问题", pinyin: "wèntí", meaning: "question" },
  908: { character: "我", pinyin: "wǒ", meaning: "I" },
  909: { character: "我们", pinyin: "wǒmen", meaning: "us" },
  910: { character: "污染", pinyin: "wūrǎn", meaning: "Pollution" },
  911: { character: "无", pinyin: "wú", meaning: "none" },
  912: { character: "无聊", pinyin: "wúliáo", meaning: "boring" },
  913: { character: "无论", pinyin: "wúlùn", meaning: "regardless" },
  914: { character: "五", pinyin: "wǔ", meaning: "five" },
  915: { character: "误会", pinyin: "wùhuì", meaning: "Misunderstand" },
  916: { character: "西", pinyin: "xī", meaning: "West" },
  917: { character: "西瓜", pinyin: "xīguā", meaning: "watermelon" },
  918: { character: "西红柿", pinyin: "xīhóngshì", meaning: "tomato" },
  919: { character: "吸引", pinyin: "xīyǐn", meaning: "absorb" },
  920: { character: "希望", pinyin: "xīwàng", meaning: "hope" },
  921: { character: "习惯", pinyin: "xíguàn", meaning: "Habit" },
  922: { character: "洗", pinyin: "xǐ", meaning: "wash" },
  923: { character: "洗手间", pinyin: "xǐshǒujiān", meaning: "Toilet" },
  924: { character: "洗澡", pinyin: "xǐzǎo", meaning: "bath" },
  925: { character: "喜欢", pinyin: "xǐhuān", meaning: "like" },
  926: { character: "下", pinyin: "xià", meaning: "Down" },
  927: { character: "下午", pinyin: "xiàwǔ", meaning: "afternoon" },
  928: { character: "下雨", pinyin: "xià yǔ", meaning: "rain" },
  929: { character: "夏", pinyin: "xià", meaning: "summer" },
  930: { character: "先", pinyin: "xiān", meaning: "First" },
  931: { character: "先生", pinyin: "xiānshēng", meaning: "gentlemen" },
  932: { character: "咸", pinyin: "xián", meaning: "salty" },
  933: { character: "现金", pinyin: "xiànjīn", meaning: "cash" },
  934: { character: "现在", pinyin: "xiànzài", meaning: "Now" },
  935: { character: "羡慕", pinyin: "xiànmù", meaning: "envious" },
  936: { character: "相反", pinyin: "xiāngfǎn", meaning: "on the contrary" },
  937: { character: "相同", pinyin: "xiāngtóng", meaning: "same" },
  938: { character: "相信", pinyin: "xiāngxìn", meaning: "Believe" },
  939: { character: "香", pinyin: "xiāng", meaning: "fragrant" },
  940: { character: "香蕉", pinyin: "xiāngjiāo", meaning: "banana" },
  941: { character: "详细", pinyin: "xiángxì", meaning: "detailed" },
  942: { character: "响", pinyin: "xiǎng", meaning: "ring" },
  943: { character: "想", pinyin: "xiǎng", meaning: "think" },
  944: { character: "向", pinyin: "xiàng", meaning: "Towards" },
  945: { character: "像", pinyin: "xiàng", meaning: "picture" },
  946: { character: "橡皮", pinyin: "xiàngpí", meaning: "Rubber" },
  947: { character: "消息", pinyin: "xiāoxī", meaning: "information" },
  948: { character: "小", pinyin: "xiǎo", meaning: "Small" },
  949: { character: "小吃", pinyin: "xiǎochī", meaning: "snack" },
  950: { character: "小伙子", pinyin: "xiǎohuǒzi", meaning: "lad" },
  951: { character: "小姐", pinyin: "xiǎojiě", meaning: "Miss" },
  952: { character: "小时", pinyin: "xiǎoshí", meaning: "Hour" },
  953: { character: "小说", pinyin: "xiǎoshuō", meaning: "novel" },
  954: { character: "小心", pinyin: "xiǎoxīn", meaning: "Be careful" },
  955: { character: "校长", pinyin: "xiàozhǎng", meaning: "headmaster" },
  956: { character: "笑", pinyin: "xiào", meaning: "laugh" },
  957: { character: "笑话", pinyin: "xiàohuà", meaning: "joke" },
  958: { character: "效果", pinyin: "xiàoguǒ", meaning: "Effect" },
  959: { character: "些", pinyin: "xiē", meaning: "some" },
  960: { character: "写", pinyin: "xiě", meaning: "Write" },
  961: { character: "谢谢", pinyin: "xièxiè", meaning: "thanks" },
  962: { character: "心情", pinyin: "xīnqíng", meaning: "Feeling" },
  963: { character: "辛苦", pinyin: "xīnkǔ", meaning: "Hard" },
  964: { character: "新", pinyin: "xīn", meaning: "new" },
  965: { character: "新闻", pinyin: "xīnwén", meaning: "news" },
  966: { character: "新鲜", pinyin: "xīnxiān", meaning: "Fresh" },
  967: { character: "信封", pinyin: "xìnfēng", meaning: "envelope" },
  968: { character: "信息", pinyin: "xìnxī", meaning: "information" },
  969: { character: "信心", pinyin: "xìnxīn", meaning: "confidence" },
  970: { character: "信用卡", pinyin: "xìnyòngkǎ", meaning: "credit card" },
  971: { character: "兴奋", pinyin: "xīngfèn", meaning: "excited" },
  972: { character: "星期", pinyin: "xīngqí", meaning: "Week" },
  973: { character: "行", pinyin: "xíng", meaning: "Row" },
  974: { character: "行李箱", pinyin: "xínglǐ xiāng", meaning: "suitcase" },
  975: { character: "醒", pinyin: "xǐng", meaning: "Awake" },
  976: { character: "幸福", pinyin: "xìngfú", meaning: "happiness" },
  977: { character: "性别", pinyin: "xìngbié", meaning: "gender" },
  978: { character: "性格", pinyin: "xìnggé", meaning: "character" },
  979: { character: "姓", pinyin: "xìng", meaning: "surname" },
  980: { character: "熊猫", pinyin: "xióngmāo", meaning: "panda" },
  981: { character: "休息", pinyin: "xiūxí", meaning: "rest" },
  982: { character: "修理", pinyin: "xiūlǐ", meaning: "repair" },
  983: { character: "需要", pinyin: "xūyào", meaning: "need" },
  984: { character: "许多", pinyin: "xǔduō", meaning: "many" },
  985: { character: "选择", pinyin: "xuǎnzé", meaning: "choose" },
  986: { character: "学期", pinyin: "xuéqí", meaning: "semester" },
  987: { character: "学生", pinyin: "xuéshēng", meaning: "student" },
  988: { character: "学习", pinyin: "xuéxí", meaning: "study" },
  989: { character: "学校", pinyin: "xuéxiào", meaning: "School" },
  990: { character: "雪", pinyin: "xuě", meaning: "Snow" },
  991: { character: "压力", pinyin: "yālì", meaning: "pressure" },
  992: { character: "呀", pinyin: "ya", meaning: "ah" },
  993: { character: "牙膏", pinyin: "yágāo", meaning: "toothpaste" },
  994: { character: "亚洲", pinyin: "yàzhōu", meaning: "Asia" },
  995: { character: "严格", pinyin: "yángé", meaning: "strict" },
  996: { character: "严重", pinyin: "yánzhòng", meaning: "serious" },
  997: { character: "研究", pinyin: "yánjiū", meaning: "Research" },
  998: { character: "盐", pinyin: "yán", meaning: "Salt" },
  999: { character: "颜色", pinyin: "yánsè", meaning: "color" },
  1000: { character: "眼睛", pinyin: "yǎnjīng", meaning: "Eye" },
  1001: { character: "眼镜", pinyin: "yǎnjìng", meaning: "Glasses" },
  1002: { character: "演出", pinyin: "yǎnchū", meaning: "show" },
  1003: { character: "演员", pinyin: "yǎnyuán", meaning: "actor" },
  1004: { character: "羊肉", pinyin: "yángròu", meaning: "mutton" },
  1005: { character: "阳光", pinyin: "yángguāng", meaning: "Sunlight" },
  1006: { character: "养成", pinyin: "yǎng chéng", meaning: "cultivate" },
  1007: { character: "样子", pinyin: "yàngzi", meaning: "look" },
  1008: { character: "要求", pinyin: "yāoqiú", meaning: "Require" },
  1009: { character: "邀请", pinyin: "yāoqǐng", meaning: "invite" },
  1010: { character: "药", pinyin: "yào", meaning: "medicine" },
  1011: { character: "要", pinyin: "yào", meaning: "want" },
  1012: { character: "要是", pinyin: "yàoshi", meaning: "if" },
  1013: { character: "钥匙", pinyin: "yàoshi", meaning: "key" },
  1014: { character: "爷爷", pinyin: "yéyé", meaning: "grandfather" },
  1015: { character: "也", pinyin: "yě", meaning: "also" },
  1016: { character: "也许", pinyin: "yěxǔ", meaning: "Maybe" },
  1017: { character: "叶子", pinyin: "yèzi", meaning: "leaf" },
  1018: { character: "页", pinyin: "yè", meaning: "Page" },
  1019: { character: "一", pinyin: "yī", meaning: "one" },
  1020: { character: "一般", pinyin: "yībān", meaning: "generally" },
  1021: { character: "一边", pinyin: "yībiān", meaning: "side" },
  1022: { character: "一点儿", pinyin: "yīdiǎn er", meaning: "a little" },
  1023: { character: "一定", pinyin: "yīdìng", meaning: "must" },
  1024: { character: "一共", pinyin: "yīgòng", meaning: "total" },
  1025: { character: "一会儿", pinyin: "yīhuǐ'er", meaning: "a while" },
  1026: { character: "一起", pinyin: "yīqǐ", meaning: "Together" },
  1027: { character: "一切", pinyin: "yīqiè", meaning: "everything" },
  1028: { character: "一下", pinyin: "yīxià", meaning: "a bit" },
  1029: { character: "一样", pinyin: "yīyàng", meaning: "Same" },
  1030: { character: "一直", pinyin: "yīzhí", meaning: "Always" },
  1031: { character: "衣服", pinyin: "yīfú", meaning: "clothing" },
  1032: { character: "医生", pinyin: "yīshēng", meaning: "doctor" },
  1033: { character: "医院", pinyin: "yīyuàn", meaning: "Hospital" },
  1034: { character: "已经", pinyin: "yǐjīng", meaning: "already" },
  1035: { character: "以", pinyin: "yǐ", meaning: "by" },
  1036: { character: "以前", pinyin: "yǐqián", meaning: "before" },
  1037: { character: "以为", pinyin: "yǐwéi", meaning: "Think" },
  1038: { character: "椅子", pinyin: "yǐzi", meaning: "Chair" },
  1039: { character: "艺术", pinyin: "yìshù", meaning: "Art" },
  1040: { character: "意见", pinyin: "yìjiàn", meaning: "Opinion" },
  1041: { character: "意思", pinyin: "yìsi", meaning: "to mean, meaning" },
  1042: { character: "因此", pinyin: "yīncǐ", meaning: "therefore" },
  1043: {
    character: "因为…所以…",
    pinyin: "yīnwèi…suǒyǐ…",
    meaning: "Because Therefore…",
  },
  1044: { character: "阴", pinyin: "yīn", meaning: "Negative" },
  1045: { character: "音乐", pinyin: "yīnyuè", meaning: "music" },
  1046: { character: "银行", pinyin: "yínháng", meaning: "bank" },
  1047: { character: "引起", pinyin: "yǐnqǐ", meaning: "cause" },
  1048: { character: "饮料", pinyin: "yǐnliào", meaning: "drinks" },
  1049: { character: "印象", pinyin: "yìnxiàng", meaning: "impression" },
  1050: { character: "应该", pinyin: "Yīnggāi", meaning: "should" },
  1051: { character: "赢", pinyin: "yíng", meaning: "win" },
  1052: { character: "影响", pinyin: "yǐngxiǎng", meaning: "influences" },
  1053: { character: "应聘", pinyin: "yìngpìn", meaning: "apply" },
  1054: { character: "永远", pinyin: "yǒngyuǎn", meaning: "forever" },
  1055: { character: "勇敢", pinyin: "yǒnggǎn", meaning: "brave" },
  1056: { character: "用", pinyin: "yòng", meaning: "use" },
  1057: { character: "优点", pinyin: "yōudiǎn", meaning: "advantage" },
  1058: { character: "优秀", pinyin: "yōuxiù", meaning: "excellent" },
  1059: { character: "幽默", pinyin: "yōumò", meaning: "humor" },
  1060: { character: "尤其", pinyin: "yóuqí", meaning: "especially" },
  1061: { character: "由", pinyin: "yóu", meaning: "Depend on" },
  1062: { character: "由于", pinyin: "yóuyú", meaning: "because" },
  1063: { character: "邮局", pinyin: "yóujú", meaning: "post office" },
  1064: { character: "游戏", pinyin: "yóuxì", meaning: "game" },
  1065: { character: "游泳", pinyin: "yóuyǒng", meaning: "swim" },
  1066: { character: "友好", pinyin: "yǒuhǎo", meaning: "friendly" },
  1067: { character: "友谊", pinyin: "yǒuyì", meaning: "friendship" },
  1068: { character: "有", pinyin: "yǒu", meaning: "Have" },
  1069: { character: "有名", pinyin: "yǒumíng", meaning: "famous" },
  1070: { character: "有趣", pinyin: "yǒuqù", meaning: "interesting" },
  1071: { character: "又", pinyin: "yòu", meaning: "again" },
  1072: { character: "右边", pinyin: "yòubiān", meaning: "right" },
  1073: { character: "于是", pinyin: "yúshì", meaning: "then" },
  1074: { character: "鱼", pinyin: "yú", meaning: "fish" },
  1075: { character: "愉快", pinyin: "yúkuài", meaning: "joy" },
  1076: { character: "与", pinyin: "yǔ", meaning: "and" },
  1077: { character: "羽毛球", pinyin: "yǔmáoqiú", meaning: "badminton" },
  1078: { character: "语法", pinyin: "yǔfǎ", meaning: "grammar" },
  1079: { character: "语言", pinyin: "yǔyán", meaning: "language" },
  1080: { character: "预习", pinyin: "yùxí", meaning: "preview" },
  1081: { character: "遇到", pinyin: "yù dào", meaning: "meet" },
  1082: { character: "元", pinyin: "yuán", meaning: "Yuan" },
  1083: { character: "原来", pinyin: "yuánlái", meaning: "turn out to be" },
  1084: { character: "原谅", pinyin: "yuánliàng", meaning: "forgive" },
  1085: { character: "原因", pinyin: "yuányīn", meaning: "reason" },
  1086: { character: "远", pinyin: "yuǎn", meaning: "Far" },
  1087: { character: "愿意", pinyin: "yuànyì", meaning: "willing" },
  1088: { character: "约会", pinyin: "yuēhuì", meaning: "Dating" },
  1089: { character: "月", pinyin: "yuè", meaning: "moon" },
  1090: { character: "月亮", pinyin: "yuèliàng", meaning: "moon" },
  1091: { character: "阅读", pinyin: "yuèdú", meaning: "read" },
  1092: { character: "越", pinyin: "yuè", meaning: "cross" },
  1093: { character: "云", pinyin: "yún", meaning: "cloud" },
  1094: { character: "允许", pinyin: "yǔnxǔ", meaning: "allow" },
  1095: { character: "运动", pinyin: "yùndòng", meaning: "sports" },
  1096: { character: "杂志", pinyin: "zázhì", meaning: "Magazine" },
  1097: { character: "再", pinyin: "zài", meaning: "Again" },
  1098: { character: "再见", pinyin: "zàijiàn", meaning: "goodbye" },
  1099: { character: "在", pinyin: "zài", meaning: "exist" },
  1100: { character: "咱们", pinyin: "zánmen", meaning: "we" },
  1101: { character: "暂时", pinyin: "zhànshí", meaning: "temporary" },
  1102: { character: "脏", pinyin: "zàng", meaning: "dirty" },
  1103: { character: "早上", pinyin: "zǎoshang", meaning: "Morning" },
  1104: { character: "责任", pinyin: "zérèn", meaning: "responsibility" },
  1105: { character: "怎么", pinyin: "zěnme", meaning: "how" },
  1106: { character: "怎么样", pinyin: "zěnme yàng", meaning: "How about it" },
  1107: { character: "增加", pinyin: "zēngjiā", meaning: "Increase" },
  1108: { character: "占线", pinyin: "zhànxiàn", meaning: "busy" },
  1109: { character: "站", pinyin: "zhàn", meaning: "stand" },
  1110: { character: "张", pinyin: "zhāng", meaning: "open, long" },
  1111: {
    character: "长（动词）",
    pinyin: "zhǎng (dòngcí)",
    meaning: "long (verb)",
  },
  1112: { character: "丈夫", pinyin: "zhàngfū", meaning: "husband" },
  1113: { character: "招聘", pinyin: "zhāopìn", meaning: "Recruitment" },
  1114: { character: "着急", pinyin: "zhāojí", meaning: "in a hurry" },
  1115: { character: "找", pinyin: "zhǎo", meaning: "Find" },
  1116: { character: "照", pinyin: "zhào", meaning: "According to" },
  1117: { character: "照顾", pinyin: "zhàogù", meaning: "take care of" },
  1118: { character: "照片", pinyin: "zhàopiàn", meaning: "photo" },
  1119: { character: "照相机", pinyin: "zhàoxiàngjī", meaning: "camera" },
  1120: { character: "这", pinyin: "zhè", meaning: "This" },
  1121: { character: "着", pinyin: "zhe", meaning: "writing" },
  1122: { character: "真", pinyin: "zhēn", meaning: "real" },
  1123: { character: "真正", pinyin: "zhēnzhèng", meaning: "real" },
  1124: { character: "整理", pinyin: "zhěnglǐ", meaning: "tidy" },
  1125: { character: "正常", pinyin: "zhèngcháng", meaning: "normal" },
  1126: { character: "正好", pinyin: "zhènghǎo", meaning: "just right" },
  1127: { character: "正确", pinyin: "zhèngquè", meaning: "correct" },
  1128: { character: "正式", pinyin: "zhèngshì", meaning: "formal" },
  1129: { character: "正在", pinyin: "zhèngzài", meaning: "is" },
  1130: { character: "证明", pinyin: "zhèngmíng", meaning: "prove" },
  1131: { character: "之", pinyin: "zhī", meaning: "Of" },
  1132: { character: "支持", pinyin: "zhīchí", meaning: "support" },
  1133: {
    character: "只（量词）",
    pinyin: "zhǐ (liàngcí)",
    meaning: "only (quantifier)",
  },
  1134: { character: "知道", pinyin: "zhīdào", meaning: "Know" },
  1135: { character: "知识", pinyin: "zhīshì", meaning: "Knowledge" },
  1136: { character: "直接", pinyin: "zhíjiē", meaning: "direct" },
  1137: { character: "值得", pinyin: "zhídé", meaning: "worth" },
  1138: { character: "职业", pinyin: "zhíyè", meaning: "Profession" },
  1139: { character: "植物", pinyin: "zhíwù", meaning: "plant" },
  1140: {
    character: "只（副词）",
    pinyin: "zhǐ (fùcí)",
    meaning: "only (adverb)",
  },
  1141: { character: "只好", pinyin: "zhǐhǎo", meaning: "had to" },
  1142: { character: "只要", pinyin: "zhǐyào", meaning: "if only" },
  1143: {
    character: "只有…才…",
    pinyin: "zhǐyǒu…cái…",
    meaning: "only... that...",
  },
  1144: { character: "指", pinyin: "zhǐ", meaning: "refer to" },
  1145: { character: "至少", pinyin: "zhìshǎo", meaning: "At least" },
  1146: { character: "质量", pinyin: "zhìliàng", meaning: "quality" },
  1147: { character: "中国", pinyin: "zhōngguó", meaning: "China" },
  1148: { character: "中间", pinyin: "zhōngjiān", meaning: "middle" },
  1149: { character: "中文", pinyin: "zhōngwén", meaning: "Chinese" },
  1150: { character: "中午", pinyin: "zhōngwǔ", meaning: "noon" },
  1151: { character: "终于", pinyin: "zhōngyú", meaning: "finally" },
  1152: {
    character: "种（量词）",
    pinyin: "zhǒng (liàngcí)",
    meaning: "species (quantifier)",
  },
  1153: { character: "重", pinyin: "zhòng", meaning: "Heavy" },
  1154: { character: "重点", pinyin: "zhòngdiǎn", meaning: "focus" },
  1155: { character: "重视", pinyin: "zhòngshì", meaning: "Pay attention to" },
  1156: { character: "重要", pinyin: "zhòngyào", meaning: "important" },
  1157: { character: "周末", pinyin: "zhōumò", meaning: "weekend" },
  1158: { character: "周围", pinyin: "zhōuwéi", meaning: "around" },
  1159: { character: "主要", pinyin: "zhǔyào", meaning: "main" },
  1160: { character: "主意", pinyin: "zhǔyì", meaning: "idea" },
  1161: { character: "住", pinyin: "zhù", meaning: "live" },
  1162: { character: "注意", pinyin: "zhùyì", meaning: "Notice" },
  1163: { character: "祝贺", pinyin: "zhùhè", meaning: "congratulate" },
  1164: { character: "著名", pinyin: "zhùmíng", meaning: "Famous" },
  1165: { character: "专门", pinyin: "zhuānmén", meaning: "specialized" },
  1166: { character: "专业", pinyin: "zhuānyè", meaning: "specialized" },
  1167: { character: "转", pinyin: "zhuǎn", meaning: "change" },
  1168: { character: "赚", pinyin: "zhuàn", meaning: "earn" },
  1169: { character: "准备", pinyin: "zhǔnbèi", meaning: "Prepare" },
  1170: { character: "准确", pinyin: "zhǔnquè", meaning: "precise" },
  1171: { character: "准时", pinyin: "zhǔnshí", meaning: "on time" },
  1172: { character: "桌子", pinyin: "zhuōzi", meaning: "table" },
  1173: { character: "仔细", pinyin: "zǐxì", meaning: "carefulness" },
  1174: { character: "自己", pinyin: "zìjǐ", meaning: "Own" },
  1175: { character: "自然", pinyin: "zìrán", meaning: "nature" },
  1176: { character: "自信", pinyin: "zìxìn", meaning: "confidence" },
  1177: { character: "自行车", pinyin: "zìxíngchē", meaning: "bike" },
  1178: { character: "字", pinyin: "zì", meaning: "character" },
  1179: { character: "总结", pinyin: "zǒngjié", meaning: "Summarize" },
  1180: { character: "总是", pinyin: "zǒng shì", meaning: "always" },
  1181: { character: "走", pinyin: "zǒu", meaning: "Walk" },
  1182: { character: "租", pinyin: "zū", meaning: "rent" },
  1183: { character: "嘴", pinyin: "zuǐ", meaning: "mouth" },
  1184: { character: "最", pinyin: "zuì", meaning: "most" },
  1185: { character: "最好", pinyin: "zuì hǎo", meaning: "most" },
  1186: { character: "最后", pinyin: "zuìhòu", meaning: "at last" },
  1187: { character: "最近", pinyin: "zuìjìn", meaning: "recent" },
  1188: { character: "尊重", pinyin: "zūnzhòng", meaning: "respect" },
  1189: { character: "昨天", pinyin: "zuótiān", meaning: "yesterday" },
  1190: { character: "左边", pinyin: "zuǒbiān", meaning: "left" },
  1191: { character: "左右", pinyin: "zuǒyòu", meaning: "about" },
  1192: { character: "作家", pinyin: "zuòjiā", meaning: "writer" },
  1193: { character: "作业", pinyin: "zuòyè", meaning: "Operation" },
  1194: { character: "作用", pinyin: "zuòyòng", meaning: "effect" },
  1195: { character: "作者", pinyin: "zuòzhě", meaning: "author" },
  1196: { character: "坐", pinyin: "zuò", meaning: "sit" },
  1197: { character: "座", pinyin: "zuò", meaning: "seat" },
  1198: { character: "座位", pinyin: "zuòwèi", meaning: "seat" },
  1199: {
    character: "做",
    pinyin: "zuò",
    meaning: "Do",
  },
};

export { hsk1Dict, hsk2Dict, hsk3Dict, hsk4Dict, hskAllDict };
